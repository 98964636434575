import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { reportHeading } from '../helper';

function FirstPageSection() {
  const { reportPdfResponse1, checkedSections, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const frontPageData = reportPdfResponse1?.FrontPage;
  const { selectedSignature } = useSelector((state:RootState) => state.reportWorkspaceReducer);
  const { BorderColor } = themePdfColor;

  return (
    <>
      <div className="p30">
        <table className="w100 logoTable">
          <tr>
            {/* <td style={{ width: '70%' }}>
              {checkedSections?.includes('1')
            && <img src={frontPageData?.LogoUrl} alt="Logo" className="companyLogo" />}
            </td> */}
            <td style={{ width: '65%' }}>
              {checkedSections?.includes('1') && frontPageData?.LogoUrl.length > 0 && (
              <img src={frontPageData?.LogoUrl} alt="Logo" className="companyLogo" />
              )}
            </td>
            <td style={{ width: '35%' }}>
              <div style={{
                fontWeight: '600', fontSize: '17px',
              }}
              >
                {frontPageData?.ClientName}
              </div>
              <div style={{ fontWeight: '600', fontSize: '15px' }}>
                {frontPageData?.ClientAddress?.split('\n').map((line, index) => (
                  <React.Fragment key={`${line[0]}-${index + 1}`}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
              <div style={{ fontWeight: '600', fontSize: '15px' }}>
                {frontPageData?.ClientPhone}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="p70">&nbsp;</td>
          </tr>
        </table>
        <table className="w100 logoContentTable">
          <tr>
            <td
              colSpan={2}
              className="textCenter"
              style={{ fontWeight: '600', fontSize: '18px' }}
            >
              {`Report of ${reportHeading(frontPageData?.TestType)}`}
            </td>
          </tr>
          <tr>
            <td className="w40 textRight">Manufacturer:</td>
            <td className="w60">
            &nbsp;
              {frontPageData?.Manufacturer}
            </td>
          </tr>
          <tr>
            <td className="w40 textRight">Model:</td>
            <td className="w60">
            &nbsp;
              {frontPageData?.Model}
            </td>
          </tr>
          <tr>
            <td className="w40 textRight">Serial:</td>
            <td className="w60">
            &nbsp;
              {frontPageData?.SerialNumber}
            </td>
          </tr>
          <tr>
            <td className="w40 textRight vAlignTop">Location:</td>
            <td className="w60 vAlignTop">
              {frontPageData?.Location?.split('\n').map((line, index) => (
                <React.Fragment key={`${line[0]}-${index + 1}`}>
                &nbsp;
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </td>
          </tr>
          <tr>
            <td className="w40 textRight">Inspected:</td>
            <td className="w60">
            &nbsp;
              {frontPageData?.InspectionDate}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="p30">&nbsp;</td>
          </tr>
          <tr>
            <td className="w40 textRight vAlignTop">Inspected By:</td>
            <td className="w60 vAlignTop">
                  &nbsp;
              {frontPageData?.InspectedBy}
              <br />
                  &nbsp;
              {frontPageData?.InspectedByDesignation}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="p50">&nbsp;</td>
          </tr>
          <tr>
            <td className="w40 textRight">Reviewed By:</td>
            <td className="w60">
              <img
                style={{
                  width: 'auto',
                  height: '65px',
                  marginLeft: '16px',
                  borderBottom: '1px solid #000000',
                  paddingBottom: '3px',
                }}
                src={selectedSignature}
                alt=""
              />
              <div style={{
                marginLeft: '16px',
              }}
              >
                {frontPageData?.ReviewedBy}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="h35 pt40">
              <div className="thickLine" style={{ backgroundColor: BorderColor }}>&nbsp;</div>
              <div className="thinLine" style={{ backgroundColor: BorderColor }}>&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="h35">
              <div className="footerText textCenter">{frontPageData?.ReviewedByDesignation}</div>
            </td>
          </tr>
        </table>
      </div>
      <div className="page-break" />
    </>
  );
}

export default FirstPageSection;
