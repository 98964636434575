/* eslint-disable no-nested-ternary */
import {
  AddContactModalFields,
  BillingInfoForm, SelectedContact, ContactsResponseData, ContactsData, CustomerDetails, CustomerInformationForm,
  CutomerContactForm,
  MainFormValues, PrimaryContactForms, SelectedCustomerDetails,
} from './types';

export function formatTableData(data: ContactsResponseData[]) {
  const res: ContactsData[] = [];

  data.forEach((col: ContactsResponseData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      status: '',
      name: '',
      office: '',
      email: '',
      phone: '',
      ext: '',
      cell: '',
      er: false,
      or: false,
      id: '',
    };

    obj.status = col.IsActive ? 'Active' : 'InActive';
    obj.name = `${col.FirstName} ${col.LastName}`;
    obj.office = col.Office;
    obj.email = col.Email;
    obj.phone = col.Phone;
    obj.ext = col.Ext;
    obj.phone = col.Phone;
    obj.cell = col.Cell;
    obj.er = col.EctReport;
    obj.or = col.OilReport;
    obj.id = String(col.ContactId);

    res.push(obj);
  });

  return res;
}

export function assignValues(selectedCustomerDetails: SelectedCustomerDetails, setValue: SetValue) {
  [
    { name: PrimaryContactForms.title, value: selectedCustomerDetails?.PrimaryContactInformation?.SaluationId },
    { name: PrimaryContactForms.firstName, value: selectedCustomerDetails?.PrimaryContactInformation?.FirstName },
    { name: PrimaryContactForms.lastName, value: selectedCustomerDetails?.PrimaryContactInformation?.LastName },
    { name: PrimaryContactForms.primaryEmailId, value: selectedCustomerDetails?.PrimaryContactInformation?.EmailId },
    { name: PrimaryContactForms.contactCode, value: selectedCustomerDetails?.PrimaryContactInformation?.PhoneCode },
    { name: PrimaryContactForms.phone, value: selectedCustomerDetails?.PrimaryContactInformation?.Phone },
    { name: PrimaryContactForms.alternativePhone, value: selectedCustomerDetails?.PrimaryContactInformation?.AlternativePhone },
    { name: BillingInfoForm.sameCompanyAddress, value: selectedCustomerDetails?.BillingInformation?.SameAsCompanyAddress },
    { name: BillingInfoForm.creditCardRequired, value: selectedCustomerDetails?.BillingInformation?.CreditCardRequired },
    { name: BillingInfoForm.approvedPO, value: selectedCustomerDetails?.BillingInformation?.ApprovedForPO },
    { name: BillingInfoForm.billToCorporate, value: selectedCustomerDetails?.BillingInformation?.BillToCorporateId },
    { name: BillingInfoForm.billingAddress, value: selectedCustomerDetails?.BillingInformation?.Address },
    { name: BillingInfoForm.billingOptionalAddress, value: selectedCustomerDetails?.BillingInformation?.OptionalAddress },
    { name: BillingInfoForm.billingZip, value: selectedCustomerDetails?.BillingInformation?.ZipCode },
    { name: BillingInfoForm.billingState, value: selectedCustomerDetails?.BillingInformation?.StateName },
    { name: BillingInfoForm.billingCity, value: selectedCustomerDetails?.BillingInformation?.CityName },
    { name: BillingInfoForm.billingCountry, value: selectedCustomerDetails?.BillingInformation?.CountryCode },
    { name: CutomerContactForm.contactTitle, value: selectedCustomerDetails?.ContractorContact?.SaluationId },
    { name: CutomerContactForm.contactFirstName, value: selectedCustomerDetails?.ContractorContact?.FirstName },
    { name: CutomerContactForm.contactLastName, value: selectedCustomerDetails?.ContractorContact?.LastName },
    { name: CutomerContactForm.contactEmailId, value: selectedCustomerDetails?.ContractorContact?.EmailId },
    { name: CutomerContactForm.contactCountryCode, value: selectedCustomerDetails?.ContractorContact?.PhoneCode },
    { name: CutomerContactForm.contactPhone, value: selectedCustomerDetails?.ContractorContact?.Phone },
    { name: CutomerContactForm.contactAlternativePhone, value: selectedCustomerDetails?.ContractorContact?.AlternativePhone },
    { name: CustomerInformationForm.customerAlert, value: selectedCustomerDetails?.CompanyInformation?.CustomerAlert },
    { name: CustomerInformationForm.smc, value: selectedCustomerDetails?.CompanyInformation?.SMC },
    { name: CustomerInformationForm.bidAlert, value: selectedCustomerDetails?.CompanyInformation?.BidAlert },
    { name: CustomerInformationForm.markup, value: selectedCustomerDetails?.CompanyInformation?.MarkupId },
    { name: CustomerInformationForm.customerName, value: selectedCustomerDetails?.CompanyInformation?.CustomerName },
    { name: CustomerInformationForm.address, value: selectedCustomerDetails?.CompanyInformation?.Address },
    { name: CustomerInformationForm.companyCountryCode, value: selectedCustomerDetails?.CompanyInformation?.PhoneCode },
    { name: CustomerInformationForm.companyPhone, value: selectedCustomerDetails?.CompanyInformation?.Phone },
    { name: CustomerInformationForm.companyExt, value: selectedCustomerDetails?.CompanyInformation?.Extension },
    { name: CustomerInformationForm.companyAddress, value: selectedCustomerDetails?.CompanyInformation?.OptionalAddress },
    { name: CustomerInformationForm.companyZip, value: selectedCustomerDetails?.CompanyInformation?.ZipCode },
    { name: CustomerInformationForm.companyState, value: selectedCustomerDetails?.CompanyInformation?.StateName },
    { name: CustomerInformationForm.companyCity, value: selectedCustomerDetails?.CompanyInformation?.CityName },
    { name: CustomerInformationForm.companyCountry, value: selectedCustomerDetails?.CompanyInformation?.CountryCode },
    { name: CustomerInformationForm.companyName, value: selectedCustomerDetails?.CompanyInformation?.SmcName },
    { name: CustomerInformationForm.smcId, value: selectedCustomerDetails?.CompanyInformation?.SmcId },
    { name: CustomerInformationForm.customerNote, value: selectedCustomerDetails?.CompanyInformation?.CustomerNote },
    { name: CustomerInformationForm.bidAlertNote, value: selectedCustomerDetails?.CompanyInformation?.BidAlertNote },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function formatMainRequest(data: MainFormValues, customerId: string) {
  const req: CustomerDetails = {
    CustomerId: customerId,
    CompanyInformation: {
      CustomerAlert: data.customerAlert,
      SMC: data.smc,
      BidAlert: data.bidAlert,
      MarkupId: data.markup,
      CustomerName: data.customerName,
      Address: data.address,
      PhoneCode: data.companyCountryCode,
      Phone: data.companyPhone,
      Extension: data.companyExt,
      OptionalAddress: data.companyAddress,
      ZipCode: data.companyZip || '',
      CountryCode: data.companyCountry,
      // CityName: data.companyZip ? data.companyCity === 'Other' ? data?.companyOtherCity || '' : data.companyCity || '' : data.location,
      CityName: data.companyCity === 'Other' ? data?.companyOtherCity || '' : data.companyCity || '',
      IsOtherCity: data?.companyCity === 'Other',
      CustomerNote: data.customerNote,
      BidAlertNote: data.bidAlertNote,
      SmcId: data.smc ? data.smcId : '',
    },
    PrimaryContactInformation: {
      SaluationId: Number(data.title),
      FirstName: data.firstName,
      LastName: data.lastName,
      EmailId: data.primaryEmailId,
      PhoneCode: data.contactCode,
      Phone: data.phone,
      AlternativePhone: data.alternativePhone,
    },
    BillingInformation: {
      SameAsCompanyAddress: data.sameCompanyAddress,
      CreditCardRequired: data.creditCardRequired,
      ApprovedForPO: data.approvedPO,
      BillToCorporateId: data.billToCorporate,
      Address: data.billingAddress,
      OptionalAddress: data.billingOptionalAddress,
      ZipCode: data.billingZip,
      StateId: data.billingState,
      CityName: data.billingCity === 'Other' ? data?.billingOtherCity : data.billingCity,
      IsOtherCity: data?.billingCity === 'Other',
      CountryCode: data.billingCountry,
    },
    ContractorContact: {
      SaluationId: Number(data.contactTitle),
      FirstName: data.contactFirstName,
      LastName: data.contactLastName,
      EmailId: data.contactEmailId,
      PhoneCode: data.contactCountryCode,
      Phone: data.contactPhone,
      AlternativePhone: data.contactAlternativePhone,
    },
  };

  return req;
}

export function assignContactValues(selectedContact: SelectedContact, setValue: SetValue) {
  [
    { name: AddContactModalFields.title, value: selectedContact?.SaluationId },
    { name: AddContactModalFields.firstName, value: selectedContact?.FirstName },
    { name: AddContactModalFields.lastName, value: selectedContact?.LastName },
    { name: AddContactModalFields.emailId, value: selectedContact?.Email },
    { name: AddContactModalFields.office, value: selectedContact?.Office },
    { name: AddContactModalFields.address, value: selectedContact?.Address },
    { name: AddContactModalFields.zip, value: selectedContact?.ZipCode },
    { name: AddContactModalFields.state, value: selectedContact?.StateName },
    { name: AddContactModalFields.city, value: selectedContact?.CityName },
    { name: AddContactModalFields.country, value: selectedContact?.CountryCode },
    { name: AddContactModalFields.code, value: selectedContact?.PhoneCode },
    { name: AddContactModalFields.phone, value: selectedContact?.Phone },
    { name: AddContactModalFields.ext, value: selectedContact?.Extension },
    { name: AddContactModalFields.cell, value: selectedContact?.Cell },
    { name: AddContactModalFields.monthlyReport, value: selectedContact?.RecieveMonthlyReport },
    { name: AddContactModalFields.ECTReport, value: selectedContact?.EctReport },
    { name: AddContactModalFields.oilReport, value: selectedContact?.OilReport },
  ].forEach(({ name, value }) => setValue(name, value));
}

export enum ThemesFormValue {
  Main_Color = 'MainColor',
  Border_Color = 'BorderColor',
  First_Font_Color = 'FirstFontColor',
  Second_Font_Color = 'SecondFontColor',
  Pen_Color = 'PenColor',
  Background_Color = 'BackgroundColor',
}

export const MainThemesDefaultValue = {
  MainColor: '#0185FF',
  BorderColor: '#0185FF',
  FirstFontColor: '#0185FF',
  SecondFontColor: '#0185FF',
  PenColor: '#0185FF',
  BackgroundColor: '#0185FF',
};
