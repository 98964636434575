/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import ComponentBarChart from './ComponentBarChart';
import { RootState } from '../../../redux/rootState';
import { VesselReport } from '../types';
import BarChartTable from './barChartTable';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function BarChartSection() {
  const { reportPdfResponse1, themePdfColor, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor } = themePdfColor;

  return (
    <>
      {
      reportPdfResponse1.Comparison_Graphs?.map((details: VesselReport, index) => (
        <div key={index}>
          <div className="pageHeader">
            <div className="topHeader">
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>
                {/* {`Part- ${tableContent.indexOf(CombineReportEnum.COMBINE_DEFECT_GRAPH > '0' ? CombineReportEnum.COMBINE_DEFECT_GRAPH : PDFReportEnum.PDF_DEFECT_GRAPH)} Comparison Graphs`} */}
                {ReportPart1(tableContent, CombineReportEnum.COMBINE_DEFECT_GRAPH, PDFReportEnum.PDF_DEFECT_GRAPH, 'Comparison Graphs')}
                { index > 0
                && (
                <>
                  <br />
                  Defect Summary/Comparison - Page
                  {' '}
                  {index + 1}
                </>
                )}
              </div>
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>{`S/N ${reportPdfResponse1?.JobOrderData?.SerialNumber}`}</div>
            </div>
            <div className="underlineHeadingText textCenter">Defect Summary/Comparison</div>
          </div>
          <div className="bayLengthHeader mb30">
            <div>Comparison of Tests Performed</div>
            {/* <div>6/24/2024 4/26/2006</div> */}
          </div>
          <ComponentBarChart graphData={details} primaryColor={BorderColor} />
          <div className="mt20">
            <BarChartTable />
          </div>
          { reportPdfResponse1.Comparison_Graphs.length > 0 && <div className="page-break" /> }
        </div>
      ))
    }
    </>
  );
}

export default BarChartSection;
