import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  GridType, TubeChartGridProps,
} from '../../../utils/tubeChartTypes';
import { setRelativeCord } from '../../../redux/tubeChart/tubeChartSlice';
import { RootState } from '../../../../../redux/rootState';

export function TubeChartGrid({
  polygon, setPolyCircles, polyCircles, index, isPdfReport = false,
}:TubeChartGridProps) {
  const dispatch = useDispatch();
  const {
    vanishType, scale, selectedSection, sectionValues,
  } = useSelector((state:RootState) => state.TubeChartReducer);

  const getRowMargin = () => {
    const row = polygon.rows;
    const col = polygon.columns;
    switch (true) {
      case (row > 90 || col > 90):
        return styles.eccentricMargin5;

      case ((row > 50 && row <= 90) || (col > 60 && col <= 90)):
        return styles.eccentricMargin7;

      case ((row > 50 && row <= 80) || (col > 50 && col <= 80)):
        return styles.eccentricMargin8;

      case ((row > 30 && row <= 50) || (col > 30 && col <= 50)):
        return styles.eccentricMargin12;

      case (row <= 30 && col <= 30):
        return styles.eccentricMargin;

      default:
        return styles.eccentricMargin;
    }
  };

  const getFontSize = () => {
    const row = polygon.rows;
    const col = polygon.columns;
    switch (true) {
      case (row > 90 || col > 90):
        return '4px';

      case ((row > 50 && row <= 90) || (col > 60 && col <= 90)):
        return '4px';

      case ((row > 50 && row <= 80) || (col > 50 && col <= 80)):
        return '5px';

      case ((row > 30 && row <= 50) || (col > 30 && col <= 50)):
        return '5px';

      case (row <= 30 && col <= 30):
        return '5px';

      default:
        return '5px';
    }
  };

  const getRowClassName = (i:number) => {
    switch (polygon.gridType) {
      case GridType.LeftEccentric:
        return i % 2 !== 0 ? getRowMargin() : '';
      case GridType.TopEccentric:
        return styles.rowItemInverted;
      default:
        return '';
    }
  };

  const isSelectedSection = useMemo(() => {
    if (sectionValues?.length === 1) return true;
    if (index === Number(selectedSection) - 1) return true;
    return false;
  }, [index, selectedSection, sectionValues]);

  useEffect(() => {
    const temp = polyCircles;
    temp[index] = polygon.circles;
    setPolyCircles([...temp]);
  }, []);

  const handleCircleClick = (x:number, y:number) => {
    if (!isSelectedSection) return;
    polygon.toggleVisibility(x, y, vanishType);
    const temp = polyCircles;
    temp[index] = polygon.circles;
    setPolyCircles([...temp]);
  };

  const handleMouseEnter = (x:number, y:number) => {
    if (isSelectedSection) {
      dispatch(setRelativeCord({ x: polyCircles?.[index][x][y].relX, y: polyCircles?.[index][x][y].relY }));
    } else {
      dispatch(setRelativeCord({ x: 0, y: 0 }));
    }
  };

  const handleMouseLeave = () => {
    dispatch(setRelativeCord({ x: 0, y: 0 }));
  };

  const getClassName = (visibility: boolean) => {
    const row = polygon.rows;
    const col = polygon.columns;
    switch (true) {
      case (row > 90 || col > 90):
        return visibility ? styles.extraSmallDotButton : styles.extraSmallDotButtonInactive;

      case ((row > 50 && row <= 90) || (col > 60 && col <= 90)):
        return visibility ? styles.mediumSmallDotButton : styles.mediumSmallDotButtonInactive;

      case ((row > 50 && row <= 80) || (col > 50 && col <= 80)):
        return visibility ? styles.smallDotButton : styles.smallDotButtonInactive;

      case ((row > 30 && row <= 50) || (col > 30 && col <= 50)):
        return visibility ? styles.MediumDotButton : styles.MediumDotButtonInactive;

      case (row <= 30 && col <= 30):
        return visibility ? styles.dotButton : styles.dotButtonInactive;

      default:
        return styles.dotButtonInactive;
    }
  };

  const getPolygonClass = () => {
    if (polygon.gridType === GridType.TopEccentric) {
      return isPdfReport ? `${styles.gridInvertedRow} ${styles.pdfTubeChartCenter}` : styles.gridInvertedRow;
    }
    return styles.polygrame;
  };

  return (
    <div
      className={isPdfReport ? styles.tubeChartBoxPdf : styles.tubeChartBox}
    >
      <div
        className={`${styles.mainPolygonClass} ${getPolygonClass()}`}
        // style={{ transform: `scale(${scale})` }}
        style={{
          transform: polygon.section === selectedSection ? `scale(${scale})` : 'none',
        }}
        onMouseLeave={handleMouseLeave}
      >
        {polyCircles?.[index]?.map((row, i) => (
          <div key={`row_${row[i]}_${i + 1}`} className={`${styles.gridRow} ${getRowClassName(i)}`}>
            {row.map((data, j) => (
              <div
                onMouseEnter={() => {
                  handleMouseEnter(i, j);
                }}
                onMouseLeave={handleMouseLeave}
                key={`col_${row[i]}_${j + 8}`}
                className={
                    `${getClassName(data.visible)}
                      ${(j % 2 !== 0 && polygon.gridType === GridType.TopEccentric) ? styles.invertedmargin : ''}`
                    }
                style={data.visible ? {
                  backgroundColor: data.defectColor,
                  fontSize: getFontSize(),
                  display: 'flex',
                  justifyContent: 'center',
                  placeItems: 'center',
                  fontWeight: '400',
                } : {}}
                onClick={() => handleCircleClick(i, j)}
              >
                {data.defectColor !== '' && data.visible ? data.defectTag : ''}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
