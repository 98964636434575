/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector } from 'react-redux';
import ComponentStripsChart from './ComponentStripsChart';
import StripChartTable from './stripChartTable';
import { StripChartSectionProps, TubeData } from '../types';
import { RootState } from '../../../redux/rootState';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function StripChartSection({ showTable, data, label }:StripChartSectionProps) {
  const { reportPdfResponse1, reportPdfResponse3, tableContent } = useSelector((state: RootState) => state.reportPdfSliceReducer);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { Calibration_Table } = reportPdfResponse3;

  return (
    <>
      {
      data?.map((item, dIndex) => (
        <>
          {dIndex > 0 && <div className="page-break" />}
          <div className="p30" key={item[0]?.Id}>
            <div className="pageHeader">
              <div className="topHeader">
                {ReportPart1(tableContent, CombineReportEnum.COMBINE_STRIP_CHARTS, PDFReportEnum.PDF_STRIP_CHARTS, 'Strip Charts')}
                {/* <div>Test Instrument Used Highline Automation, EC14</div> */}
                <div />
                <div>{`S/N ${reportPdfResponse1?.JobOrderData?.SerialNumber}`}</div>
              </div>
              <div className="underlineHeadingText textCenter">{label}</div>
              {/* {showTable && Calibration_Table[dIndex] && (
                <>
                  <StripChartTable calibrationTableData={Calibration_Table[dIndex]} />
                </>
              )} */}
              {showTable && (
                // Map through Calibration_Table to find all matching entries
                // eslint-disable-next-line max-len
                Calibration_Table?.filter((calibration: TubeData) => calibration.VesselName === item[0]?.VesselName).map((calibrationData: TubeData, index: number) => (
                  <StripChartTable
                    // eslint-disable-next-line react/no-array-index-key
                    key={index} // Ensure a unique key for each StripChartTable
                    calibrationTableData={calibrationData}
                  />
                ))
              )}
            </div>
          </div>
          <>
            {item?.map((details, index) => (
              <div key={details.Id}>
                {(index > 0) && <div className="page-break" />}
                <ComponentStripsChart newDetails={details} showSn={index > 0} />
              </div>
            ))}
          </>
        </>
      ))
    }
    </>
  );
}

export default StripChartSection;
