export const MainDefaultValue = {
  smc: false,
  site: '',
  contact: '',
  address: '',
  addressOptional: '',
  country: 'US',
  zip: 'TAI0000',
  state: '',
  city: 'NAN',
  code: '+1',
  phone: '',
  cell: '',
  safetyCompanyName: '',
  siteType: '',
  note: '',
  smcId: '',
};
