import React, { useEffect, useRef } from 'react';
import {
  Card,
  Grid,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { BidPriceDetailNames } from '../../utils/constants';
import { RootState } from '../../../../../redux/rootState';
import {
  assignBidPricing, constructBidPricingPayload, getBidValue, getValidNumeric,
} from '../../utils/helper';
import { ComponentBidDetailsProps, UpdateBidPayload } from '../../utils/types';
import { BidDetailsFormComponent } from './formComponent';
import { updateBidStart } from '../../redux/bidWorksheetSlice';

function ComponentBidDetails({
  control, setValue, watch,
}:ComponentBidDetailsProps) {
  const {
    selectedBid, bidPricing, bidId, selectedTab,
  } = useSelector((state:RootState) => state.bidsReducer);
  const dispatch = useDispatch();

  const bidPricingValues = watch();

  const flightCharge = watch(BidPriceDetailNames.FlightCharge) || 0;
  const airfare = watch(BidPriceDetailNames.Airfare) || 0;
  const eqCharge = watch(BidPriceDetailNames.EquipmentCharges);
  const otherCharge = watch(BidPriceDetailNames.OtherCharges) || 0;
  const SafetyTraining = watch(BidPriceDetailNames.SafetyTraining) || 0;
  const SafetyTrainingCharges = watch(BidPriceDetailNames.SafetyTrainingCharges) || 0;
  const nonProdDays = watch(BidPriceDetailNames.NonProdDays);
  const CommunicationCharges = watch(BidPriceDetailNames.CommunicationCharges);
  const ParkingChanges = watch(BidPriceDetailNames.ParkingChanges);
  const SafetyCharges = watch(BidPriceDetailNames.SafetyCharges);
  const NightCharges = watch(BidPriceDetailNames.NightCharges);
  const OTSaturday = watch(BidPriceDetailNames.OTSaturday);
  const OTSunday = watch(BidPriceDetailNames.OTSunday);
  const OTHoliday = watch(BidPriceDetailNames.OTHoliday);
  const AdditionalCharges = watch(BidPriceDetailNames.AdditionalCharges);
  const TotalFlightExpense = watch(BidPriceDetailNames.TotalFlightExpense);
  const TotalMiscExpense = watch(BidPriceDetailNames.TotalMiscExpense);
  const NonProdDaysAmount = watch(BidPriceDetailNames.NonProdDaysAmount);
  const TubeCharge = watch(BidPriceDetailNames.TubeCharge);
  const DriveExpense = watch(BidPriceDetailNames.DriveExpense);
  const LodgePerDiem = watch(BidPriceDetailNames.LodgePerDiem);
  const Markup = watch(BidPriceDetailNames.Markup);
  const FluxLeakageDays = watch(BidPriceDetailNames.FluxLeakageDays);
  const AbsShipping = watch(BidPriceDetailNames.AbsShipping);
  const FluxShipping = watch(BidPriceDetailNames.FluxShipping);
  const CalculatedDays = watch(BidPriceDetailNames.CalculatedDays);
  const OverRideDays = watch(BidPriceDetailNames.OverRideDays);
  const CorrectionType = watch(BidPriceDetailNames.CorrectionType);
  const CorrectionFactor = watch(BidPriceDetailNames.CorrectionFactor);

  useEffect(() => {
    if (bidPricing?.AdditionalInfo && !selectedBid?.BidId) {
      setValue(BidPriceDetailNames.FlightCharge, getBidValue(bidPricing?.FlightExpense));
      setValue(BidPriceDetailNames.EquipmentCharges, getBidValue(bidPricing?.EquipmentCharge));
    }
  }, [bidPricing]);

  // Flight Expense
  useEffect(() => {
    if (getValidNumeric(airfare) > 0) {
      setValue(BidPriceDetailNames.TotalFlightExpense, Math.ceil(getValidNumeric(flightCharge) + getValidNumeric(airfare)));
    } else {
      setValue(BidPriceDetailNames.TotalFlightExpense, 0);
    }
  }, [flightCharge, airfare]);

  // Misc Expense
  useEffect(() => {
    setValue(BidPriceDetailNames.TotalMiscExpense, Math.ceil(getValidNumeric(eqCharge) + getValidNumeric(otherCharge)) || 0);
  }, [eqCharge, otherCharge]);

  // Additional Charges
  useEffect(() => {
    const val = getValidNumeric(CommunicationCharges)
    + getValidNumeric(ParkingChanges) + getValidNumeric(SafetyTrainingCharges) + getValidNumeric(SafetyCharges) + getValidNumeric(NightCharges)
    + getValidNumeric(OTSaturday) + getValidNumeric(OTSunday) + getValidNumeric(OTHoliday);
    setValue(BidPriceDetailNames.AdditionalCharges, Math.ceil(val));
  }, [CommunicationCharges, ParkingChanges, SafetyCharges, NightCharges, OTSaturday, OTSunday, OTHoliday, SafetyTrainingCharges]);

  useEffect(() => {
    if (selectedBid) {
      assignBidPricing(setValue, selectedBid);
    }
  }, [selectedBid]);

  // total Markup
  useEffect(() => {
    if (bidPricing?.MarkupPercentage) {
      const markup = bidPricing?.MarkupPercentage;
      const val = getValidNumeric(TubeCharge) + getValidNumeric(CalculatedDays)
      + getValidNumeric(OverRideDays) + getValidNumeric(FluxLeakageDays) + getValidNumeric(DriveExpense)
      + getValidNumeric(LodgePerDiem) + getValidNumeric(TotalFlightExpense)
      + getValidNumeric(TotalMiscExpense) + getValidNumeric(NightCharges) + getValidNumeric(OTSaturday)
      + getValidNumeric(OTSunday) + getValidNumeric(OTHoliday) + getValidNumeric(SafetyTrainingCharges) + getValidNumeric(NonProdDaysAmount);
      setValue(BidPriceDetailNames.Markup, Math.ceil(getValidNumeric((markup / 100) * val)));
    }
  }, [TubeCharge, CalculatedDays, OverRideDays, FluxLeakageDays, DriveExpense, NonProdDaysAmount,
    LodgePerDiem, TotalFlightExpense, TotalMiscExpense, NightCharges, OTSaturday, OTSunday, OTHoliday, SafetyTrainingCharges]);

  // Total Bid
  useEffect(() => {
    const correctionVal = CorrectionType === '1' ? -1 * (CorrectionFactor) : CorrectionFactor;
    const val = [
      getValidNumeric(TotalMiscExpense), getValidNumeric(TotalFlightExpense),
      getValidNumeric(NonProdDaysAmount), getValidNumeric(TubeCharge), getValidNumeric(DriveExpense),
      getValidNumeric(AdditionalCharges), getValidNumeric(LodgePerDiem), getValidNumeric(Markup),
      getValidNumeric(FluxShipping), getValidNumeric(AbsShipping), getValidNumeric(correctionVal)]
      .reduce((acc:number, curr:number) => acc + curr, 0);
    setValue(BidPriceDetailNames.TotalBid, Math.ceil(val) || 0);
  }, [TotalMiscExpense, TotalFlightExpense, NonProdDaysAmount, CorrectionType, CorrectionFactor,
    TubeCharge, DriveExpense, AdditionalCharges, LodgePerDiem, Markup, FluxShipping, AbsShipping]);

  useEffect(() => {
    if (getValidNumeric(SafetyTraining) > 2 && bidPricing?.AdditionalInfo?.SafetyTraining) {
      setValue(
        BidPriceDetailNames.SafetyTrainingCharges,
        (getValidNumeric(bidPricing?.AdditionalInfo?.SafetyTraining) * getValidNumeric(SafetyTraining)) || 0,
      );
    }
  }, [SafetyTraining]);

  useEffect(() => {
    if (bidPricing?.NonProdDaysAmount) {
      setValue(BidPriceDetailNames.NonProdDaysAmount, (getValidNumeric(nonProdDays) * getValidNumeric(bidPricing?.NonProdDaysAmount)));
    }
  }, [nonProdDays]);

  const handleSave = () => {
    const bidPayload = selectedBid?.BidDetails;
    const bidPricingPayload = constructBidPricingPayload(bidPricingValues);
    const payload: UpdateBidPayload = {
      BidId: bidId,
      BidDetails: { ...bidPayload, JobId: selectedBid?.BidDetails.JobId },
      BidPriceDetails: bidPricingPayload,
    };
    dispatch(updateBidStart(payload));
  };

  const targetDivRef5 = useRef<HTMLButtonElement | null>(null);

  const goToTop = () => {
    const targetDiv = targetDivRef5.current;
    targetDiv?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <>
      {selectedTab === 2
      && (
      <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '8px' }}>
        <Button type="button" className="button_save_and_next" onClick={() => goToTop()}>Go to Save</Button>
      </div>
      )}
      <Card>
        <div className="special_location_container">
          <div className="customerInfo">
            Bid Details
          </div>
        </div>
        <div className="customerInfo_main_container">
          <BidDetailsFormComponent control={control} watch={watch} setValue={setValue} />
        </div>
      </Card>
      {selectedTab === 2
      && (
      <Grid item xs={12} sm={12} md={12}>
        <div className="save_and_next_div mt20">
          <Button ref={targetDivRef5} type="submit" className="button_save_and_next" onClick={handleSave}>Save</Button>
        </div>
      </Grid>
      )}
    </>
  );
}

export default ComponentBidDetails;
