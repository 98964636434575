import {
  Control, FieldErrors, UseFormTrigger, UseFormWatch,
} from 'react-hook-form';
import { PinCodeDetails } from '../../../utils/type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Empty = any;
export interface MainFormValues {
  // PrimaryContact Iformation
  title: number;
  firstName: string;
  lastName: string | Empty;
  primaryEmailId: string | Empty;
  contactCode: string | Empty;
  phone: string | Empty;
  alternativePhone: string | Empty;

  // Company Information
  customerAlert: boolean | Empty;
  smc: boolean | Empty;
  bidAlert: boolean | Empty;
  markup: string;
  customerName: string;
  address: string;
  companyCountryCode: string | Empty;
  companyPhone: string | Empty;
  companyExt: string | Empty;
  companyAddress: string | Empty;
  companyZip: string;
  companyState: string;
  companyCity: string;
  companyOtherCity: string | Empty;
  companyCountry: string;
  companyName: string | Empty;
  customerNote: string | Empty;
  bidAlertNote: string | Empty;
  smcId: string | Empty;

  // Billing Information
  sameCompanyAddress: boolean | Empty;
  creditCardRequired: boolean | Empty;
  approvedPO: boolean | Empty;
  billToCorporate: string | Empty;
  billingAddress: string | Empty;
  billingOptionalAddress: string | Empty;
  billingZip: string | Empty;
  billingState: string | Empty;
  billingCity: string | Empty;
  billingOtherCity: string | Empty;
  billingCountry: string | Empty;
  // Contact Information
  contactTitle: number | Empty;
  contactFirstName: string | Empty;
  contactLastName: string | Empty;
  contactEmailId: string | Empty;
  contactCountryCode: string | Empty;
  contactPhone: string | Empty;
  contactAlternativePhone: string | Empty;
}

export interface IComponentPrimaryContact {
  control: Control<MainFormValues>;
  errors: FieldErrors<MainFormValues>;
}

export interface IBillingInformation {
  control: Control<MainFormValues>;
  setValue: SetValue;
  watch: UseFormWatch<MainFormValues>;
  getValues: (val: string) => string;
}

export interface ICustomerContact {
  control: Control<MainFormValues>
  errors: FieldErrors<MainFormValues>;
}

export interface ICustomerInformation {
  control: Control<MainFormValues>;
  errors: FieldErrors<MainFormValues>;
  watch: UseFormWatch<MainFormValues>;
  setValue: SetValue;
  trigger: UseFormTrigger<MainFormValues>;
}

export enum PrimaryContactForms {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  primaryEmailId = 'primaryEmailId',
  contactCode = 'contactCode',
  phone = 'phone',
  alternativePhone = 'alternativePhone',
}

export enum BillingInfoForm {
  sameCompanyAddress = 'sameCompanyAddress',
  creditCardRequired = 'creditCardRequired',
  approvedPO = 'approvedPO',
  billToCorporate = 'billToCorporate',
  billingAddress = 'billingAddress',
  billingOptionalAddress = 'billingOptionalAddress',
  billingZip = 'billingZip',
  billingState = 'billingState',
  billingCity = 'billingCity',
  billingCountry = 'billingCountry',
  BILLING_OTHER_CITY = 'billingOtherCity',
}

export enum CutomerContactForm {
  contactTitle = 'contactTitle',
  contactFirstName = 'contactFirstName',
  contactLastName = 'contactLastName',
  contactEmailId = 'contactEmailId',
  contactCountryCode = 'contactCountryCode',
  contactPhone = 'contactPhone',
  contactAlternativePhone = 'contactAlternativePhone',
}

export enum CustomerInformationForm {
  customerAlert = 'customerAlert',
  smc = 'smc',
  bidAlert = 'bidAlert',
  markup = 'markup',
  customerName = 'customerName',
  address = 'address',
  companyCountryCode = 'companyCountryCode',
  companyPhone = 'companyPhone',
  companyExt = 'companyExt',
  companyAddress = 'companyAddress',
  companyZip = 'companyZip',
  companyState = 'companyState',
  companyCity = 'companyCity',
  companyOtherCity = 'companyOtherCity',
  companyCountry = 'companyCountry',
  companyName = 'companyName',
  customerNote = 'customerNote',
  bidAlertNote = 'bidAlertNote',
  smcId = 'smcId',
  COMPANY_OTHER_CITY = 'companyOtherCity',
  // location = 'location',
}

export interface ContactsData {
  sn: number;
  action: string;
  status: string;
  name: string;
  office: string;
  email: string;
  phone: string;
  ext: string;
  cell: string;
  er: boolean;
  or: boolean;
  id: string;
}

export interface TypesDeleteModal {
  show: boolean;
  setOpenFrom: () => void;
}

export interface TypesEditContactModal {
  show: boolean;
  setOpenFrom: () => void;
}

export interface IAddContactModal {
  title: number;
  firstName: string;
  lastName: string | undefined;
  emailId: string | undefined;
  office: string | undefined;
  address: string | undefined;
  zip: string;
  state: string;
  city: string;
  contactOtherCity : string;
  country: string;
  code: string;
  phone: string;
  ext: string | undefined;
  cell: string | undefined;
  monthlyReport: boolean | undefined;
  ECTReport: boolean | undefined;
  oilReport: boolean | undefined;
}

export enum AddContactModalFields {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  emailId = 'emailId',
  office = 'office',
  address = 'address',
  zip = 'zip',
  state = 'state',
  city = 'city',
  CONTACT_OTHER_CITY = 'contactOtherCity',
  country = 'country',
  code = 'code',
  phone = 'phone',
  ext = 'ext',
  cell = 'cell',
  monthlyReport = 'monthlyReport',
  ECTReport = 'ECTReport',
  oilReport = 'oilReport',
}
export interface ContactDetails {
  ContactId?: number;
  CustomerId: string,
  SaluationId: number | undefined,
  FirstName: string | undefined,
  LastName: string | undefined,
  Email: string | undefined,
  Office: string | undefined,
  Address: string | undefined,
  ZipCode: string | undefined,
  StateCode: string | undefined,
  CityName: string;
  IsOtherCity: boolean;
  CountryCode: string | undefined,
  PhoneCode: string | undefined,
  Phone: string | undefined,
  Extension: string | undefined,
  Cell: string | undefined,
  RecieveMonthlyReport: boolean | undefined,
  EctReport: boolean | undefined,
  OilReport: boolean | undefined,
  page: number,
  rowsPerPage: number,
  searchQuery: string,
}

export interface CustomerDetails {
  CustomerId: string;
  CompanyInformation: {
    CustomerAlert: boolean | undefined;
    SMC: boolean | undefined;
    BidAlert: boolean | undefined;
    MarkupId: string | undefined;
    CustomerName: string | undefined;
    Address: string;
    PhoneCode: string | undefined;
    Phone: string | undefined;
    Extension: string | undefined;
    OptionalAddress: string | undefined;
    ZipCode: string;
    CountryCode: string;
    CityName: string;
    IsOtherCity: boolean;
    CustomerNote: string | undefined;
    BidAlertNote: string | undefined;
    SmcId?: string | undefined;
  };
  PrimaryContactInformation: {
    SaluationId: number;
    FirstName: string;
    LastName: string | undefined;
    EmailId: string | undefined;
    PhoneCode: string | undefined;
    Phone: string | undefined;
    AlternativePhone: string | undefined;
  };
  BillingInformation: {
    SameAsCompanyAddress: boolean | undefined;
    CreditCardRequired: boolean | undefined;
    ApprovedForPO: boolean | undefined;
    BillToCorporateId: string | undefined;
    // BillToCorporate: string | undefined;
    Address: string | undefined;
    OptionalAddress: string | undefined;
    ZipCode: string | undefined;
    StateId: string | undefined;
    CityName: string | undefined;
    IsOtherCity: boolean;
    CountryCode: string | undefined;
  };
  ContractorContact: {
    SaluationId: number | undefined;
    FirstName: string | undefined;
    LastName: string | undefined;
    EmailId: string | undefined;
    PhoneCode: string | undefined;
    Phone: string | undefined;
    AlternativePhone: string | undefined;
  };
}

export interface SelectedCustomerDetails {
  CustomerId: string;
  CompanyInformation: {
    CustomerAlert: boolean | undefined;
    SMC: boolean | undefined;
    BidAlert: boolean | undefined;
    MarkupId: string | undefined;
    CustomerName: string;
    CustomerNote: string | undefined;
    BidAlertNote: string | undefined;
    Address: string;
    PhoneCode: string | undefined;
    Phone: string | undefined;
    Extension: string | undefined;
    OptionalAddress: string | undefined;
    ZipCode: string;
    StateName: string;
    CityName: string;
    IsOtherCity: boolean;
    CountryCode: string;
    SmcId: string | undefined;
    SmcName: string;
  };
  PrimaryContactInformation: {
    SaluationId: string;
    FirstName: string;
    LastName: string | undefined;
    EmailId: string | undefined;
    PhoneCode: string;
    Phone: string | undefined;
    AlternativePhone: string | undefined;
  };
  BillingInformation: {
    SameAsCompanyAddress: boolean | undefined;
    CreditCardRequired: boolean | undefined;
    ApprovedForPO: boolean | undefined;
    BillToCorporateId: string | undefined;
    // BillToCorporate: string | undefined;
    Address: string | undefined;
    OptionalAddress: string | undefined;
    ZipCode: string | undefined;
    StateName: string | undefined;
    CityName: string | undefined;
    IsOtherCity: string;
    CountryCode: string | undefined;
  };
  ContractorContact: {
    SaluationId: string | undefined;
    FirstName: string | undefined;
    LastName: string | undefined;
    EmailId: string | undefined;
    PhoneCode: string | undefined;
    Phone: string | undefined;
    AlternativePhone: string | undefined;
  };
  Comments: {
    CustomerId: string;
    Comments: string;
    Instructions: string;
    LoginUserId: string;
  };
  ThemeDetails: {
    Id: number;
    CustId: string;
    MainColor: string;
    BorderColor: string;
    Font1Color: string;
    Font2Color: string;
    PenColor: string;
    BackGroundColor: string;
    Logo: string;
  };
}

export interface ThemeDetails {
  Id: number;
  CustId: string;
  MainColor: string;
  BorderColor: string;
  Font1Color: string;
  Font2Color: string;
  PenColor: string;
  BackGroundColor: string;
  Logo: string;
}

export interface ContactsResponseData {
  ContactId: number;
  CustId: string;
  IsActive: boolean;
  FirstName: string;
  LastName: string;
  Office: string;
  Email: string;
  Phone: string;
  Ext: string;
  Cell: string;
  EctReport: boolean;
  OilReport: boolean;
}

export interface SelectedContact {
  ContactId: number;
  CustomerId: string;
  IsActive: boolean;
  SaluationId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Office: string;
  Address: string;
  ZipCode: string;
  StateName: string;
  CityName: string;
  IsOtherCity: boolean;
  CountryCode: string;
  PhoneCode: string;
  Phone: string;
  Extension: string;
  Cell: string;
  RecieveMonthlyReport: boolean;
  EctReport: boolean;
  OilReport: boolean;
  LoginUserId: string | null;
}

export interface GetContactsQuery {
  searchQuery: string;
  page: number;
  rowsPerPage: number;
  customerId: string;
}

export interface TypesAddContactModal {
  show: boolean;
  handleClose: () => void;
  mode: string;
  page: number;
  searchQuery: string;
  rowsPerPage: number;
}

export interface CommentApiPayload {
  CustomerId: string;
  Comments: string;
  Instructions: string;
}
export interface BidData {
  JobId: string;
  BidId: string;
  BidCreationDate: string;
  BidName: string;
  SiteName: string;
  City: string;
  CustomerId: string;
  TotalBid: number;
}
export interface GetJobOrderResponse {
  JobId: string;
  SiteName: string;
  Model: string;
  SerialNumber: string;
  SiteCity: string;
  ClientName: string;
  InspectedBy: string;
  StartDate: string;
}
export interface AddCustomerInitialState {
  customerDetails: CustomerDetails | null;
  contactDetails: ContactDetails;
  isLoading: boolean;
  errorMessage: string;
  contactList: ContactsResponseData[];
  selectedCustomerDetails: SelectedCustomerDetails | null;
  selectedContact: SelectedContact | null;
  getContactsQuery: GetContactsQuery | null;
  selectedTab: number;
  customerId: string;
  contactTotalEntries: number;
  commentDetails: CommentApiPayload | null;
  otherCustomerdetails: SelectedCustomerDetails;
  bidsList: BidData[];
  totalEntries: number;
  selectedColor: string;
  isSuccess: boolean;
  themeLogoURL: string;
  themeDetails: ThemeDetails;
  billingPinCodeDetails: PinCodeDetails | null;
  customerJobOrderData: GetJobOrderResponse[],
  totalJobOrderData: number,
}

export interface ResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface EditCustomerResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: CustomerDetails;
  }
}

export interface EditContactResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: ContactDetails;
  }
}

export interface GetContactListApiData {
  Data: ContactsResponseData[];
  TotalRecords: number;
}

export interface GetResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: GetContactListApiData;
  }
}

export interface CommentPayload {
  CustomerId: string;
  Comments: string;
  Instructions: string;
}

export interface AddCustomerResponse {
  CustomerId: string;
}

export interface AddCustomerApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: AddCustomerResponse;
  }
}

export interface GetCustomerByIdResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: SelectedCustomerDetails;
  }
}

export interface DeleteApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: string;
  }
}

export interface DeleteContactPayload {
  customerId: string;
  contactId: string;
}

export interface GetContactByIdPayload {
  customerId: string;
  contactId?: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface GetContactByIdApiRes {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: SelectedContact;
  }
}

export interface UpdateCommentApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: CommentApiPayload;
  }
}

export interface GetCustomerByIdPayload {
  customerId: string;
  otherCustomer: boolean;
}

export interface PayloadTypeDeleteBid {
  bidId: string;
  customerId: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

export interface PayloadTypeGetBidsStart {
  customerId: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

export interface PaginationResponse<T> {
  TotalRecords: number;
  Data: T[];
}

export interface CustomerJobPaginationResponse<T> {
  TotalCount: number;
  items: T[];
}

export interface BidsListResult {
  JobId: string;
  BidId: string;
  BidCreationDate: string;
  BidName: string;
  SiteName: string;
  City: string;
  CustomerId: string;
  TotalBid: number;
}

export interface ResultData {
  TotalRecords: number;
  Data: BidData[];
}

export interface BidsResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: {
      TotalRecords: number;
      Data: BidData[];
    };
  }
}

export interface DeleteBidApiResponse { // Delete Smc:- API responses
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface ThemeLogoApiResponseTypes {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: string;
  }
}

export interface ThemesFormInput {
  MainColor: string;
  BorderColor: string;
  FirstFontColor: string;
  SecondFontColor: string;
  PenColor: string;
  BackgroundColor: string;
}

export interface ThemePayload {
  CustId: string;
  MainColor: string;
  Font1Color: string;
  Font2Color: string;
  BorderColor: string;
  BackGroundColor: string;
  PenColor: string;
  Logo: string;
}

export interface GetJobOrderListingPayload {
  custId?: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface JobOderCustomerData {
  sn: number;
  clientName: string;
  jobId: string;
  model: string;
  serialNumber: string;
  siteCity: string;
  siteName: string;
  startDate: string;
  id: string;
}
