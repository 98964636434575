import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function DataSheetTopTable() {
  const { reportPdfResponse2, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td
          className="tableGreenHeading textCenter boldText"
          style={{
            backgroundColor: MainColor,
          }}
        >
          Location
        </td>
        <td
          className="tableGreenHeading textCenter boldText"
          style={{
            backgroundColor: MainColor,
          }}
        >
          Model
        </td>
        <td
          className="tableGreenHeading textCenter boldText"
          style={{
            backgroundColor: MainColor,
          }}
        >
          Serial Number
        </td>
        <td
          className="tableGreenHeading textCenter boldText"
          style={{
            backgroundColor: MainColor,
          }}
        >
          Date
        </td>
      </tr>
      <tr>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Location}</td>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Model}</td>
        <td className="textCenter">{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</td>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Date}</td>
      </tr>
    </table>
  );
}

export default DataSheetTopTable;
