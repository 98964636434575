import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import domtoimage from 'dom-to-image';
import { TubeChartGrid } from './tubeChartGrid';
import {
  Circle,
  GetTubeChartPayload,
  PolygonInterface,
  TubeConfiguration,
} from '../../../utils/tubeChartTypes';
import ComponentPageHeader from '../../../../../Common/PageHeader/ComponentPageHeader';
import { Polygon } from './gridClass';
import TubeChartPanel from './tubeChartPanel';
import { RootState } from '../../../../../redux/rootState';
import {
  getTubeChartByIdStart, getTubeChartsStart,
  setSelectedSection,
  updateTubeChartScreenShotStart,
  updateTubeChartStart,
} from '../../../redux/tubeChart/tubeChartSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import styles from './index.module.scss';
import { getUpdateTubePayload } from '../../../utils/helper/tubeChart';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';

export function TubeDiagram() {
  const [polygonList, setPolygonList] = useState<PolygonInterface[]>([]);
  const [polyCircles, setPolyCircles] = useState<Circle[][][]>([]);
  const [totalTubeCount, setTotalTubeCount] = useState(0);
  const [isScreenShortLoading, setIsScreenShortLoading] = useState(false);

  const {
    chartList, tubeChartDefects,
    selectedSection, isLoading,
  } = useSelector((state:RootState) => state.TubeChartReducer);
  const { tubeId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);

  const selectedSectionIndex = useMemo(() => {
    if (selectedSection) {
      return chartList.findIndex((details) => details.Panel === selectedSection);
    }
    return 0;
  }, [selectedSection, chartList?.length]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    const fetchType = searchParams.get('type');
    const jobOrder = searchParams.get('jobOrder');
    const vessel = searchParams.get('vessel');
    if (fetchType === 'all') {
      const payload:GetTubeChartPayload = {
        JobOrder: String(jobOrder),
        VesselId: String(vessel),
        Type: 2,
      };
      dispatch(getTubeChartsStart(payload));
    } else {
      dispatch(getTubeChartByIdStart(String(tubeId)));
    }
  }, [tubeId, searchParams]);

  // useEffect(() => {
  //   if (chartList.length > 0) {
  //     const polyArr:PolygonInterface[] = [];

  //     chartList.forEach((chartDetails:TubeConfiguration) => {
  //       const tempPoly = new Polygon(chartDetails);
  //       polyArr.push(tempPoly);
  //     });

  //     setPolygonList([...polyArr]);
  //   }
  // }, [chartList]);

  // useEffect(() => {
  //   if (chartList.length > 0) {
  //     const polyArr: PolygonInterface[] = [];

  //     chartList.forEach((chartDetails: TubeConfiguration) => {
  //       const tempPoly = new Polygon(chartDetails);
  //       polyArr.push(tempPoly);
  //     });

  //     const updatedPolyArr = polyArr.map((poly) => ({
  //       ...poly,
  //       section: selectedSection,
  //     }));

  //     setPolygonList(updatedPolyArr);
  //   }
  // }, [chartList, selectedSection]);

  useEffect(() => {
    if (chartList.length > 0) {
      const polyArr: PolygonInterface[] = [];

      chartList.forEach((chartDetails: TubeConfiguration) => {
        const tempPoly = new Polygon(chartDetails);
        polyArr.push(tempPoly);
      });

      const updatedPolyArr = polyArr.map((poly, index) => ({
        ...poly,
        section: chartList.length === 1 ? selectedSection : `${index + 1}`,
      }));

      setPolygonList(updatedPolyArr);
    }
  }, [chartList, selectedSection]);

  useEffect(() => {
    if (tubeChartDefects?.length > 0) {
      polygonList[Number(selectedSectionIndex)].displayDefects(tubeChartDefects);
      const temp = [...polyCircles];
      temp[selectedSectionIndex] = polygonList[Number(selectedSectionIndex)].circles;
      setPolyCircles([...temp]);
    }
  }, [tubeChartDefects]);

  const handleUndo = () => {
    if (polygonList.length > 0) {
      polygonList[Number(selectedSectionIndex)].undo();
      const temp = [...polyCircles];
      temp[selectedSectionIndex] = polygonList[Number(selectedSectionIndex)].circles;
      setPolyCircles([...temp]);
    }
  };

  const handleSectionChange = (value:string) => {
    dispatch(setSelectedSection(value));
    // const ind = chartList.findIndex((details) => details.Panel === value);
    // setPolyCircles([...polygonList[ind].circles]);
  };

  const handleSave = () => {
    const jobOrder = String(searchParams.get('jobOrder'));
    const vessel = String(searchParams.get('vessel'));
    const payload = getUpdateTubePayload(
      jobOrder,
      vessel,
      polygonList,
      chartList,
    );

    dispatch(updateTubeChartStart(payload));
    polygonList[Number(selectedSectionIndex)]?.ClearUndo();
    polygonList[Number(selectedSectionIndex)]?.removeDefects();
  };

  useEffect(() => {
    const totalCount = polygonList?.reduce((acc, curr:PolygonInterface) => acc + curr.totalVisible, 0);
    setTotalTubeCount(totalCount);
  }, [polygonList?.[Number(selectedSectionIndex)]?.totalVisible]);

  const handleScreenshot = () => {
    polygonList?.forEach((item) => {
      item.removeDefects();
    });

    if (!componentRef.current) {
      return;
    }

    setIsScreenShortLoading(true);
    const scale = 10;
    const margin = 20;

    const width = componentRef.current.scrollWidth + margin * 2;
    const height = componentRef.current.scrollHeight + margin * 2;

    const previousBodyStyles = {
      overflow: document.body.style.overflow,
    };

    const previousComponentStyles = {
      overflow: componentRef.current.style.overflow,
      width: componentRef.current.style.width,
      height: componentRef.current.style.height,
      position: componentRef.current.style.position,
      maxWidth: componentRef.current.style.maxWidth,
      maxHeight: componentRef.current.style.maxHeight,
    };

    document.body.style.overflow = 'hidden';
    componentRef.current.style.overflow = 'hidden';
    componentRef.current.style.position = 'absolute';
    componentRef.current.style.width = `${width}px`;
    componentRef.current.style.height = `${height}px`;
    componentRef.current.style.maxWidth = 'none';
    componentRef.current.style.maxHeight = 'none';

    domtoimage.toPng(componentRef.current, {
      quality: 1,
      width: (width + margin * 2) * scale,
      height: (height + margin * 2) * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        padding: `${margin}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: 'white',
      },
    })
      .then((dataUrl) => {
        const base64Image = dataUrl.split(',')[1];

        const jobOrder = String(searchParams.get('jobOrder'));
        const vessel = String(searchParams.get('vessel'));

        let payload = getUpdateTubePayload(
          jobOrder,
          vessel,
          polygonList,
          chartList,
        );

        payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

        dispatch(updateTubeChartScreenShotStart(payload));

        showSuccessToaster('Screenshot captured successfully');
        setIsScreenShortLoading(false);
      })
      .catch(() => {
        setIsScreenShortLoading(false);
        showErrorToaster('Failed to capture screenshot');
      })
      .finally(() => {
        document.body.style.overflow = previousBodyStyles.overflow;

        if (componentRef.current) {
          componentRef.current.style.overflow = previousComponentStyles.overflow;
          componentRef.current.style.width = previousComponentStyles.width;
          componentRef.current.style.height = previousComponentStyles.height;
          componentRef.current.style.position = previousComponentStyles.position;
          componentRef.current.style.maxWidth = previousComponentStyles.maxWidth;
          componentRef.current.style.maxHeight = previousComponentStyles.maxHeight;
        }
      });
  };

  const tubeChartDirectionStyle = chartList?.[0]?.Layout === '2' ? styles.displayTubeRow : styles.displayBlock;

  return (
    <div style={{ height: '100vh' }}>
      <ComponentPageHeader subHeading="Tube Chart" />
      <TubeChartPanel
        handleUndo={handleUndo}
        handleSectionChange={handleSectionChange}
        tubeCount={polygonList?.[Number(selectedSectionIndex)]?.totalVisible}
        isUndoDisable={polygonList?.[Number(selectedSectionIndex)]?.history?.length === 0}
        totalTubeCount={totalTubeCount}
        handleScreenshot={handleScreenshot}
        isScreenShortLoading={isScreenShortLoading}
      />
      <div className={`${styles.vesselDiv} save_and_next_div`}>
        <div className="button_margin_left">
          <Button className="button_save_and_next" onClick={handleSave}>Save</Button>
        </div>
      </div>
      <div ref={componentRef} className={tubeChartDirectionStyle}>
        {polygonList?.map((item, index) => (
          <TubeChartGrid
            polygon={item}
            polyCircles={polyCircles}
            index={index}
            setPolyCircles={setPolyCircles}
          />
        ))}
      </div>
    </div>
  );
}
