import * as yup from 'yup';

export const mainFormSchema = yup.object().shape({
  smc: yup.boolean(),
  site: yup.string().required('Site is required'),
  contact: yup.string(),
  address: yup.string(),
  addressOptional: yup.string(),
  country: yup.string().required('Country is required'),
  zip: yup.string(),
  state: yup.string(),
  city: yup.string(),
  code: yup.string(),
  phone: yup.string(),
  cell: yup.string(),
  siteOtherCity: yup.string(),
  // safetyCompanyName: yup.string().when('smc', {
  //   is: true,
  //   then: yup.string().required('Safety Company is required'),
  //   otherwise: yup.string(),
  // }),
  safetyCompanyName: yup.string(),
  isTicked: yup.boolean(),
  siteType: yup.string(),
  note: yup.string(),
  smcId: yup.string(),
});
