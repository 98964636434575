import React, { useMemo } from 'react';
import { VesselBaylengthGraphProps } from '../types';
import BayDetailsTable from './commonTableWithoutHeaderColumn';

function VesselBayLengthGraph({ bayDetails, primaryColor }:VesselBaylengthGraphProps) {
  const configuration = useMemo(() => {
    if (!bayDetails.IsOTE) {
      return bayDetails?.Configuration;
    }
    return [...bayDetails.Configuration].reverse();
  }, [bayDetails.IsOTE, bayDetails?.Configuration]);

  return (
    <>
      <div className="bayLengthHeader">
        <div className="pt30">
          {bayDetails.VesselName}
          {' '}
          (Length =
          {' '}
          {`${bayDetails.TubeLength}`}
          {' '}
          inches)
        </div>
        <div>S = Intermediate Support</div>
      </div>
      <div className="w100 mt10 noTableBreak">
        <div className="cylinder-label-box">
          <div className="label" style={{ color: primaryColor }}>{bayDetails.IsOTE ? 'Opp. Test End' : 'Test End'}</div>
          <div className="label" style={{ color: primaryColor }}>{bayDetails.IsOTE ? 'Test End' : 'Opp. Test End'}</div>
        </div>
        <div className="cylinder-container">
          {configuration.map((value, index) => (
            <div
              className="bay"
              style={{
                width: `${(value / Number(bayDetails?.TubeLength)) * 100}%`,
                borderColor: primaryColor,
              }}
              key={`${value}-${index + 1}`}
            >
              {index === 0
              && <div className="left-arc" style={{ borderColor: primaryColor, backgroundColor: primaryColor }} />}
              <div className="content">
                <p className="size fs14" style={{ color: primaryColor }}>
                  {value.toFixed(2)}
                  &quot;
                </p>
                <p className="fs14">
                  Bay
                  {index + 1}
                </p>
                <div className="arc" style={{ borderColor: primaryColor }} />
              </div>
              <div className="s-label fs14" style={{ color: primaryColor }}>{`S${index + 1}`}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="bayLengthTableBox w100 mt30 mb30">
        {bayDetails?.Configuration?.length > 0
        && (
        <div className="textCenter centerAlign">
          <BayDetailsTable data={bayDetails?.Configuration} primaryColor={primaryColor} />
        </div>
        )}
      </div>
    </>
  );
}

export default VesselBayLengthGraph;
