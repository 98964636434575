import * as Yup from 'yup';
import { EntriesConstants } from './constant';
import { VesselInformationMainEnum } from './mainTypes.d';

export const mainFormSchema = Yup.object().shape({
  [VesselInformationMainEnum.vessel]: Yup.string().required('Field is required'),
  [VesselInformationMainEnum.testEnd]: Yup.string(),
  [VesselInformationMainEnum.tubeType]: Yup.string(),
  [VesselInformationMainEnum.material]: Yup.string(),
  [VesselInformationMainEnum.support]: Yup.string(),
  [VesselInformationMainEnum.NWT]: Yup.string(),
  [VesselInformationMainEnum.nwtBellLand]: Yup.string(),
  [VesselInformationMainEnum.nwtUnderFins]: Yup.string(),
  [VesselInformationMainEnum.probeDiameter]: Yup.string(),
  [VesselInformationMainEnum.OD]: Yup.string(),
  [VesselInformationMainEnum.OD1]: Yup.string(),
  [VesselInformationMainEnum.OD2]: Yup.string(),
  [VesselInformationMainEnum.OD3]: Yup.string(),

  [VesselInformationMainEnum.tubeCount]: Yup.string(),
  [VesselInformationMainEnum.tubeLength]: Yup.string().required('Tube Length is Required'),

  [VesselInformationMainEnum.analyst]: Yup.string(),
  [VesselInformationMainEnum.inspectionDate]: Yup.date().required('Inspection Date is Required'),
  [VesselInformationMainEnum.retestDate]: Yup.date().required('Retest Date is Required'),
});

export const tubeChartSchema = Yup.object().shape({
  tubeSize: Yup.string().required('Tube Size is Required'),
  numberOfRows: Yup.string().required('Number Of Rows is Required'),
  rowLength: Yup.string().required('Row Length is Required'),
  vesselTop: Yup.string().required('Vessel Top is Required'),
  offset: Yup.string().required('Offset is Required'),
  numberOfTube: Yup.string(),
});

export const bayLengthSchema = Yup.object().shape({
  support: Yup.string().required('Tube Size is Required'),
  tubeCount: Yup.string(),
  tubeLength: Yup.string().required('Row Length is Required'),
});

export const startTimeSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required'),
});

export const insertTimeSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required'),
});

export const finishTimeSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required'),
});

export const addDefectSchema = Yup.object().shape({
  section: Yup.string().required('Section is required'),
  code: Yup.string().required('Code is required'),
  location: Yup.string().required('Location is required'),
  // area: Yup.string().required('Area is required'),
  area: Yup.string().nullable().test({
    name: 'required-with-location',
    test(value) {
      const { location } = this.parent;
      if (location === 'Support' || location === 'Bay') {
        return !!value || this.createError({
          message: 'Area is required',
        });
      }
      return true;
    },
  }),
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required'),
  row: Yup.string().required('Row is required'),
  tube: Yup.string().required('Tube is required'),
});

export const replaceFieldsSchema = Yup.object().shape({
  parameter: Yup.string().required('Parameter is required'),
  section: Yup.string().required('Section is required'),
  search: Yup.string().test({
    name: 'required-with-parameter',
    test(value) {
      const { parameter } = this.parent;
      if (parameter === '2' || parameter === '1') {
        return !!value || this.createError({
          message: 'Search is required',
        });
      }
      return true;
    },
  }),
  searchArea: Yup.string().test({
    name: 'required-with-parameter',
    test(value) {
      const { parameter, search } = this.parent;
      if (parameter === '2' && (search === EntriesConstants.SUPPORT || search === EntriesConstants.BAY || search === EntriesConstants.BLANK)) {
        return !!value || this.createError({
          message: 'Area is required',
        });
      }
      return true;
    },
  }),
  replaceWith: Yup.string().test({
    name: 'required-with-parameter',
    test(value) {
      const { parameter } = this.parent;
      if (parameter === '2' || parameter === '1') {
        return !!value || this.createError({
          message: 'Replace with is required',
        });
      }
      return true;
    },
  }),
  replaceWithArea: Yup.string().test({
    name: 'required-with-parameter',
    test(value) {
      const { parameter, replaceWith } = this.parent;
      if (parameter === '2' && (replaceWith === EntriesConstants.SUPPORT || replaceWith === EntriesConstants.BAY
        || replaceWith === EntriesConstants.BLANK)) {
        return !!value || this.createError({
          message: 'Area is required',
        });
      }
      return true;
    },
  }),
});
