import * as yup from 'yup';

const customerSiteFormSchema = yup.object().shape({
  customerId: yup.string().required('Customer ID is required'),
  customerName: yup.string(),
  customerContact: yup.string(),
  customerAddress: yup.string(),
  customerOptionalAdress: yup.string(),
  customerZip: yup.string(),
  customerState: yup.string(),
  customerCity: yup.string(),
  customerCountry: yup.string(),
  customerPhoneCode: yup.string(),
  customerPhone: yup.string(),
  customerSMC: yup.string(),
  contact: yup.string(),
  cell: yup.string(),
  phoneCode: yup.string(),
  phone: yup.string(),
  siteId: yup.string().required('Site ID is required'),
  siteName: yup.string(),
  siteContact: yup.string(),
  siteAddress: yup.string(),
  siteOptionalAddress: yup.string(),
  siteZip: yup.string(),
  siteState: yup.string(),
  siteCity: yup.string(),
  siteCountry: yup.string(),
  sitePhoneCode: yup.string().nullable(),
  sitePhone: yup.string(),
  siteCell: yup.string(),
  siteType: yup.string().nullable(),
  siteSMC: yup.string(),
  siteNote: yup.string(),
});

export default customerSiteFormSchema;
