import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function RecommendationSection() {
  const { reportPdfResponse2, themePdfColor, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>{ReportPart1(tableContent, CombineReportEnum.COMBINE_RECOMMENDATIONS, PDFReportEnum.PDF_RECOMMENDATIONS, '')}</div>
          <div>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">Recommendations</div>
      </div>
      <table className="w100 logoTable">
        <tr>
          <td>
            <div className="commonTableBorderStyle mt10 p30 w100 pageBorder950" style={{ borderColor: MainColor }}>
              <div
                className="tableAfterBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              <div
                className="tableBeforeBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              <pre><div style={{ whiteSpace: 'break-spaces' }}>{reportPdfResponse2?.Recommendations?.[0]?.Recommendation}</div></pre>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default RecommendationSection;
