/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import PhotosComp from './componentPhotos';

function ComponentPhotos() {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const photos = reportPdfResponse3?.Photos || [];
  const photosLength = photos.length;
  return (
    <>
      { photos?.map((data, index) => (
        <>
          {/* { index > 0 && <div className="page-break" />} */}
          <PhotosComp vesselName={data.VesselName} imageUrl={data.PhotoPath} key={data.PhotoId} photoLabel={index <= 0 ? 'Photos' : ''} />
          {index <= photosLength - 1
          && <div className="page-break" />}
        </>
      ))}
    </>

  );
}

export default ComponentPhotos;
