/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { AllDefects, TubeConfiguration } from '../../VesselInformation/utils/tubeChartTypes';
import { Polygon } from '../../VesselInformation/components/TubeChart/Diagram/gridClass';
import { TubeDetailPoly, TubePolyType } from '../types';
import TubeChartLayout from './tubeChartLayout';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function TubeBundleLayoutSection() {
  const { reportPdfResponse3, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const tubesPoly:TubePolyType[] = useMemo(
    () => reportPdfResponse3?.Tube_Bundle_Layout?.map((tubeDetails) => {
      let allDefects:AllDefects[] = [];
      const temp:TubeDetailPoly[] = tubeDetails?.map((details) => {
        const chartDetails:TubeConfiguration = {
          CircleInfos: details?.CircleInfos,
          IsOte: details?.IsOte,
          TubeSize: details?.TubeSize,
          NumOfRows: details?.NumOfRows,
          RowLength: details?.RowLength,
          Panel: details?.Panel,
          Group: details?.Group,
          Layout: details?.Layout,
          IsTestEndChanges: details.OriginalTestEnd,
          ChartId: details.ChartId,
          VesselId: details.VesselId,
          VesselTop: details.VesselTop,
          Offset: details.Offset,
          OriginalTestEnd: details.OriginalTestEnd,
          RemovedConfig: details.RemovedConfig,
        };
        const poly = new Polygon(chartDetails);
        poly.displayDefects(details?.Defects);
        allDefects = [...allDefects, ...poly.allDefects];

        return {
          poly,
          details,
        };
      });

      const uniqueDefectsMap = new Map<string, AllDefects>();

      allDefects.forEach((defect) => {
        uniqueDefectsMap.set(defect.color, defect);
      });

      const uniqueDefectsArray = Array.from(uniqueDefectsMap.values());

      return {
        tubePolyList: temp,
        allDefects: uniqueDefectsArray,
      };
    }),
    [reportPdfResponse3?.Tube_Bundle_Layout],
  );

  return (
    <div className="largePage">
      {tubesPoly?.map((tubeDetails, index) => {
        const hasVisiblePoly = tubeDetails?.tubePolyList?.some(
          (details) => details?.poly?.totalVisible > 0,
        );

        if (!hasVisiblePoly) {
          return null;
        }

        return (
          <>
            <div className="pl30 pr30 pageHeader">
              <div className="topHeader">
                <div className="mt10">
                  {ReportPart1(tableContent, CombineReportEnum.COMBINE_TUBE_CHARTS, PDFReportEnum.PDF_TUBE_CHARTS, ' - Tube Bundle Layout')}

                  {/* {`Part ${toRoman(index + 1)} - Tube Bundle Layout`} */}
                </div>
                <div>{`S/N ${reportPdfResponse3?.JobOrderData?.SerialNumber}`}</div>
              </div>
              <div className="bigHeadingText textCenter mt10">
                {tubeDetails?.tubePolyList?.[0]?.details?.VesselName}
              </div>
            </div>
            <div className="pl30 pr30 tubeBundleHeader">
              <div className="tubesNumberedArrow">
                <div>
                  Tubes Numbered
                  <br />
                  {' '}
                  Left to Right
                </div>
                <div className="mt20">
                  Rows Numbered
                  <br />
                  {' '}
                  Top to Bottom
                </div>
              </div>
              <div className="topVesselArrow">Top of Vessel</div>
              <div>{tubeDetails?.tubePolyList?.[0]?.details?.TestEnd}</div>
            </div>
            {tubeDetails?.tubePolyList?.map((details) => (
              <TubeChartLayout key={details?.details?.ChartId} poly={details?.poly} />
            ))}
            <div className="legendContainer">
              {tubeDetails?.allDefects.length > 0 ? (
                tubeDetails.allDefects.map((defect) => (
                  <div key={defect.color} className="legendItems">
                    <div
                      className="tubeChartLegend"
                      style={{
                        backgroundColor: defect.color,
                        fontSize: '10px',
                        textAlign: 'center',
                      }}
                    >
                      {defect.tag}
                    </div>
                    <div>{` = ${defect.description}`}</div>
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>No Significant defects were found.</div>
              )}
            </div>
            {index !== tubesPoly.length - 1 && <div className="page-break" />}
          </>
        );
      })}
    </div>
  );
}

export default TubeBundleLayoutSection;
