import {
  Box, Button, Grid, IconButton, Modal, TextField, Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import {
  AddBookmarkModalProps, AddBookmarkForm, AddBookmarkFields, CreateBookMarkPayload,
} from '../../utils/types';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { addBookmarkSchema, smallModalStyle } from '../../utils/constant';
import styles from '../../index.module.scss';
import RequiredFiled from '../../../../utils/RequiredField';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { createBookMarkStart, setSelectedBookmark, updateBookMarkStart } from '../../redux/calenderSlice';
import AddCustomerModal from '../../../../Common/AddCustomerModal';
import { assignBookmarkValues } from '../../utils/helper';
import { getCustomerByIdStart } from '../../../addCustomer/redux/addCustomerSlice';

function AddBookmarkModal({ show, handleClose }: AddBookmarkModalProps) {
  const { translations } = useContext(LocalizationContext);
  const {
    selectedBookmark, selectedYear, selectedMonth, selectedWeek,
  } = useSelector((state:RootState) => state.CalenderReducer);
  const { otherCustomerdetails } = useSelector((state:RootState) => state.addCustomerReducer);
  const { customers } = useSelector((state: RootState) => state.customerReducer);

  const {
    control, reset, handleSubmit, setValue, formState: { errors }, trigger,
  } = useForm<AddBookmarkForm>({
    resolver: yupResolver(addBookmarkSchema as ObjectSchema<AddBookmarkForm>),
  });

  const dispatch = useDispatch();

  const [showCustomerModal, setShowModal] = useState(false);

  const handleCustomerModalClose = () => {
    setShowModal(false);
  };

  const handleCustomerId = (customerId: string) => {
    setValue(AddBookmarkFields.customerId, customerId);
    const foundCustomerName = customers.find((item) => item.Custid === customerId);
    if (foundCustomerName) {
      const customerName = foundCustomerName.Customername;
      setValue(AddBookmarkFields.customer, customerName);
    }
    setShowModal(false);
    trigger(AddBookmarkFields.customer);
  };

  useEffect(() => {
    if (selectedBookmark?.Id) {
      assignBookmarkValues(setValue, selectedBookmark);

      dispatch(getCustomerByIdStart({ customerId: selectedBookmark.CustomerId, otherCustomer: true }));
    }
  }, [selectedBookmark]);

  useEffect(() => {
    if (otherCustomerdetails?.CompanyInformation) {
      setValue(AddBookmarkFields.customer, otherCustomerdetails.CompanyInformation.CustomerName);
    }
  }, [otherCustomerdetails]);

  const handleModalClose = () => {
    reset();
    handleClose();
    dispatch(setSelectedBookmark(null));
  };

  const handleBookMarkSubmit = (values:AddBookmarkForm) => {
    const payload:CreateBookMarkPayload = {
      Description: values.description,
      Date: values.bookmarkDate?.format('DD-MM-YYYY'),
      CustomerId: values.customerId,
      month: selectedMonth,
      year: selectedYear,
      week: selectedWeek,
    };

    if (selectedBookmark?.Id) {
      dispatch(updateBookMarkStart({ ...payload, Id: selectedBookmark?.Id }));
    } else {
      dispatch(createBookMarkStart(payload));
    }
    handleModalClose();
    reset();
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={smallModalStyle} className={styles.addEventModalContainer}>
          <form onSubmit={handleSubmit(handleBookMarkSubmit)}>
            <div className="customerInfo_container flex-row">
              <div className="customerInfo">
                {selectedBookmark?.Id ? 'Edit Bookmark' : 'Add Bookmark'}
              </div>
              <div>
                <IconButton
                  aria-label="close"
                  onClick={handleModalClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="customerInfo_main_container div_job_container_info customModalScroll">
              <Grid container spacing={2}>
                {/* {inputFields.map((field, index: number) => (
                  <> */}
                <Grid item md={5.5} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Date
                      <RequiredFiled />
                    </label>
                    <Controller
                      control={control}
                      name={AddBookmarkFields.bookmarkDate}
                      render={({ field: { value, onChange } }) => (
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={value}
                              format="MM-DD-YYYY"
                              onChange={(date) => {
                                onChange(dayjs(date));
                              }}
                              slotProps={{ textField: { size: 'small', fullWidth: true } }}
                            />
                          </LocalizationProvider>
                          {errors.bookmarkDate && (
                          <span className="errorMessage">{errors.bookmarkDate.message}</span>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </Grid>
                <Grid item md={5.5} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      {translations.customer}
                      <RequiredFiled />
                    </label>
                    <Controller
                      name={AddBookmarkFields.customer}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          size="small"
                          name={AddBookmarkFields.customer}
                          value={value}
                          disabled
                          error={!!errors.customer}
                          onChange={(event) => {
                            onChange(event);
                          }}
                          // helperText={errors?.customer?.message}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Search here..">
                                <IconButton className="search_icon_btn" onClick={() => setShowModal(true)}>
                                  <SearchIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      )}
                    />
                    {
                      errors?.customer
                    && <div className="errorMessage">{errors?.customer?.message}</div>
                    }
                  </div>
                </Grid>
                <Grid item md={11} sm={11} xs={11}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Description
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 30 }}
                      size="small"
                      name={AddBookmarkFields.description}
                      errors={errors}
                      id={AddBookmarkFields.description}
                    />
                  </div>
                </Grid>
                {/* {index !== inputFields.length - 1
                      && (
                        <Grid item xs={1} sm={1} md={1} className="addMoreBtn">
                          <div
                            onClick={() => removeHoliday(index)}
                            className="add_kits_icon"
                          >
                            <DeleteSvg />
                          </div>
                        </Grid>
                      )}
                    {index === inputFields.length - 1
                      && (
                        <Grid item xs={1} sm={1} md={1} className="addMoreBtn">
                          <div
                            onClick={() => {
                              const newField = { bookmarkDate: '', customer: '', description: '' };
                              setInputFields([...inputFields, newField]);
                            }}
                            className="add_kits_icon"
                          >
                            <img
                              src={IconList.addKitsIcon}
                              width={30}
                              height={30}
                              alt=""
                            />
                          </div>
                        </Grid>
                      )}
                    {index !== inputFields.length - 1 && (
                    <div className={styles.borderBottom} />
                    )}
                  </>
                ))} */}
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel" onClick={handleModalClose}>{translations.cancel}</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
      </Modal>
      <AddCustomerModal
        show={showCustomerModal}
        setOpenFrom={handleCustomerModalClose}
        handleTabCell={handleCustomerId}
      />
    </>
  );
}

export default AddBookmarkModal;
