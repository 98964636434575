// import React from 'react';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../redux/rootState';
// import { ExpenseReportItem } from '../../types';

// function ComponentExpenseMainTable() {
//   const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
//   const expenseMainData = reportPdfResponse4?.Expense_Report?.expenseReport;
//   const totalCheque = reportPdfResponse4?.Expense_Report?.TotalChequeFields;
//   const totalCash = reportPdfResponse4?.Expense_Report?.TotalCashFields;
//   const { BorderColor, MainColor } = themePdfColor;

//   const expenseData = (cashComp:number, chequeComp:number) => (
//     <td className="textCenter">
//       <tr style={{ display: 'flex' }}>
//         <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
//           {cashComp}
//         </td>
//         <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
//           {chequeComp}
//         </td>
//       </tr>
//     </td>
//   );

//   return (
//     <div style={{ marginTop: '20px' }}>
//       <table className="bordered-table w100">
//         <div
//           className="tableAfterThickBorderStyle"
//           style={{
//             backgroundColor: BorderColor,
//           }}
//         />
//         <div
//           className="tableBeforeThickBorderStyle"
//           style={{
//             backgroundColor: BorderColor,
//           }}
//         />
//         <tr>
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             Per Diem
//           </td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//               {maindata.ExpDate}
//             </td>
//           ))}
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             Cash
//           </td>
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             Charge
//           </td>
//         </tr>
//         <tr>
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             {/* Travel */}
//           </td>
//           {expenseMainData?.map((maindata: ExpenseReportItem) => (
//             <td key={maindata.Id} className="tableGreenHeading boldText" style={{ backgroundColor: MainColor }}>
//               <tr style={{ display: 'flex' }}>
//                 <td
//                   className="tableGreenHeading textCenter"
//                   style={{
//                     flexGrow: 1, alignContent: 'center', border: 'none', backgroundColor: MainColor,
//                   }}
//                 >
//                   Cash
//                 </td>
//                 <td
//                   className="tableGreenHeading textCenter"
//                   style={{
//                     flexGrow: 1, alignContent: 'center', border: 'none', backgroundColor: MainColor,
//                   }}
//                 >
//                   Charge
//                 </td>
//               </tr>
//             </td>
//           ))}
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Lodging</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashLodge, maindata.Chequefields.ChequeLodge)
//           ))}
//           <td className="textCenter">{totalCash?.CashLodge}</td>
//           <td className="textCenter">{totalCheque?.ChequeLodge}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Breakfast</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashBreak, maindata.Chequefields.ChequeBreak)
//           ))}
//           <td className="textCenter">{totalCash?.CashBreak}</td>
//           <td className="textCenter">{totalCheque?.ChequeBreak}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Lunch</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashLunch, maindata.Chequefields.ChequeLunch)
//           ))}
//           <td className="textCenter">{totalCash?.CashLunch}</td>
//           <td className="textCenter">{totalCheque?.ChequeLunch}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Dinner</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashDinner, maindata.Chequefields.ChequeDinner)
//           ))}
//           <td className="textCenter">{totalCash?.CashDinner}</td>
//           <td className="textCenter">{totalCheque?.ChequeDinner}</td>
//         </tr>
//         <tr>
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             Travel
//           </td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//               {maindata.ExpDate}
//             </td>
//           ))}
//           <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//             {}
//           </td>
//           <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//             {}
//           </td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Rental Car</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashCar, maindata.Chequefields.ChequeCar)
//           ))}
//           <td className="textCenter">{totalCash?.CashCar}</td>
//           <td className="textCenter">{totalCheque?.ChequeCar}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Parking/Tolls</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashPark, maindata.Chequefields.ChequePark)
//           ))}
//           <td className="textCenter">{totalCash?.CashPark}</td>
//           <td className="textCenter">{totalCheque?.ChequePark}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Gas</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashGas, maindata.Chequefields.ChequeGas)
//           ))}
//           <td className="textCenter">{totalCash?.CashGas}</td>
//           <td className="textCenter">{totalCheque?.ChequeGas}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Airfare</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashAir, maindata.Chequefields.ChequeAir)
//           ))}
//           <td className="textCenter">{totalCash?.CashAir}</td>
//           <td className="textCenter">{totalCheque?.ChequeAir}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Sky Caps</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashSkycap, maindata.Chequefields.ChequeSkycap)
//           ))}
//           <td className="textCenter">{totalCash?.CashSkycap}</td>
//           <td className="textCenter">{totalCheque?.ChequeSkycap}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Taxi</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashTaxi, maindata.Chequefields.ChequeTaxi)
//           ))}
//           <td className="textCenter">{totalCash?.CashTaxi}</td>
//           <td className="textCenter">{totalCheque?.ChequeTaxi}</td>
//         </tr>
//         <tr>
//           <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//             Other
//           </td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//               {maindata.ExpDate}
//             </td>
//           ))}
//           <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//             {}
//           </td>
//           <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
//             {}
//           </td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Entertainment</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashEnter, maindata.Chequefields.ChequeEnter)
//           ))}
//           <td className="textCenter">{totalCash?.CashEnter}</td>
//           <td className="textCenter">{totalCheque?.ChequeEnter}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Phone</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashPhone, maindata.Chequefields.ChequePhone)
//           ))}
//           <td className="textCenter">{totalCash?.CashPhone}</td>
//           <td className="textCenter">{totalCheque?.ChequePhone}</td>
//         </tr>
//         <tr>
//           <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Misc</td>
//           {expenseMainData?.map((maindata:ExpenseReportItem) => (
//             expenseData(maindata.Cashfields.CashMisc, maindata.Chequefields.ChequeMisc)
//           ))}
//           <td className="textCenter">{totalCash?.CashMisc}</td>
//           <td className="textCenter">{totalCheque?.ChequeMisc}</td>
//         </tr>
//       </table>
//     </div>
//   );
// }

// export default ComponentExpenseMainTable;

// import React from 'react';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../redux/rootState';
// import { ExpenseReportItem } from '../../types';

// function ComponentExpenseMainTable() {
//   const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
//   const expenseMainData = reportPdfResponse4?.Expense_Report?.expenseReport;
//   const totalCheque = reportPdfResponse4?.Expense_Report?.TotalChequeFields;
//   const totalCash = reportPdfResponse4?.Expense_Report?.TotalCashFields;
//   const { BorderColor, MainColor } = themePdfColor;

//   const expenseData = (cashComp: number, chequeComp: number) => (
//     <td className="textCenter">
//       <tr style={{ display: 'flex' }}>
//         <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
//           {cashComp}
//         </td>
//         <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
//           {chequeComp}
//         </td>
//       </tr>
//     </td>
//   );

//   // Helper function to chunk data into arrays of size 4
//   const chunkData = (data: ExpenseReportItem[], chunkSize: number) => {
//     const chunks = [];
//     for (let i = 0; i < data.length; i += chunkSize) {
//       chunks.push(data.slice(i, i + chunkSize));
//     }
//     return chunks;
//   };

//   const chunkedExpenseData = chunkData(expenseMainData || [], 4);

//   const renderTable = (tableData: ExpenseReportItem[], index: number) => (
//     <table className="bordered-table w100" key={`table-${index}`}>
//       <div
//         className="tableAfterThickBorderStyle"
//         style={{
//           backgroundColor: BorderColor,
//         }}
//       />
//       <div
//         className="tableBeforeThickBorderStyle"
//         style={{
//           backgroundColor: BorderColor,
//         }}
//       />
//       <tr>
//         <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//           Per Diem
//         </td>
//         {tableData.map((maindata: ExpenseReportItem) => (
//           <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }} key={maindata.Id}>
//             {maindata.ExpDate}
//           </td>
//         ))}
//         <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//           Cash
//         </td>
//         <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
//           Charge
//         </td>
//       </tr>
//       {/* Add more rows here just like in your original table */}
//       <tr>
//         <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Lodging</td>
//         {tableData.map((maindata: ExpenseReportItem) => expenseData(maindata.Cashfields.CashLodge, maindata.Chequefields.ChequeLodge))}
//         <td className="textCenter">{totalCash?.CashLodge}</td>
//         <td className="textCenter">{totalCheque?.ChequeLodge}</td>
//       </tr>
//       {/* Repeat similar rows for other categories like Breakfast, Lunch, etc. */}
//     </table>
//   );

//   return (
//     <div style={{ marginTop: '20px' }}>
//       {chunkedExpenseData.map((tableData, index) => renderTable(tableData, index))}
//     </div>
//   );
// }

// export default ComponentExpenseMainTable;

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { ExpenseReportItem, ExpenseReports } from '../../types';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import { convertDateFormat } from '../../../../utils/CommonFunctions';

function ComponentExpenseMainTable({ mainTableData }: { mainTableData: ExpenseReports }) {
  const { themePdfColor } = useSelector((state: RootState) => state.reportPdfSliceReducer);
  const expenseMainData = mainTableData?.ExpenseReports;
  const totalCheque = mainTableData?.TotalCheque;
  const totalCash = mainTableData?.TotalCash;
  const { BorderColor, MainColor } = themePdfColor;

  const expenseData = (cashComp: number, chequeComp: number) => (
    <td className="textCenter">
      <tr style={{ display: 'flex' }}>
        <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
          {cashComp}
        </td>
        <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
          {chequeComp}
        </td>
      </tr>
    </td>
  );

  // Function to divide data into chunks of 4
  const splitDataIntoTables = (data: ExpenseReportItem[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      result.push(data.slice(i, i + chunkSize));
    }
    return result;
  };

  // Split the expenseMainData into chunks of 4
  const tables = splitDataIntoTables(expenseMainData || [], 4);

  const generateKey = (item: ExpenseReportItem) => item.Id;

  return (
    <div style={{ marginTop: '20px' }}>
      {tables.map((tableData, index) => (
        <table className="bordered-table w100" style={{ marginBottom: '20px' }} key={generateKey(tableData as Empty)}>
          <div
            className="tableAfterThickBorderStyle"
            style={{
              backgroundColor: BorderColor,
            }}
          />
          <div
            className="tableBeforeThickBorderStyle"
            style={{
              backgroundColor: BorderColor,
            }}
          />
          <tr>
            <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
              Per Diem
            </td>
            {tableData.map((maindata: ExpenseReportItem) => (
              <td className="tableGreenHeading textCenter boldText" key={maindata.Id} style={{ backgroundColor: MainColor }}>
                {convertDateFormat(maindata?.ExpDate || '')}
              </td>
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  Cash
                </td>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  Charge
                </td>
              </>
            )}
          </tr>
          <tr>
            <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
              {/* Travel */}
            </td>
            {tableData.map((maindata: ExpenseReportItem) => (
              <td key={maindata.Id} className="tableGreenHeading boldText" style={{ backgroundColor: MainColor }}>
                <tr style={{ display: 'flex' }}>
                  <td
                    className="tableGreenHeading textCenter"
                    style={{
                      flexGrow: 1, alignContent: 'center', border: 'none', backgroundColor: MainColor,
                    }}
                  >
                    Cash
                  </td>
                  <td
                    className="tableGreenHeading textCenter"
                    style={{
                      flexGrow: 1, alignContent: 'center', border: 'none', backgroundColor: MainColor,
                    }}
                  >
                    Charge
                  </td>
                </tr>
              </td>
            ))}
          </tr>
          {/* Lodging */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Lodging</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashLodge, maindata.Chequefields.ChequeLodge)
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="textCenter">{totalCash?.CashLodge}</td>
                <td className="textCenter">{totalCheque?.ChequeLodge}</td>
              </>
            )}
          </tr>
          {/* Breakfast */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Breakfast</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashBreak, maindata.Chequefields.ChequeBreak)
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="textCenter">{totalCash?.CashBreak}</td>
                <td className="textCenter">{totalCheque?.ChequeBreak}</td>
              </>
            )}
          </tr>
          {/* Lunch */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Lunch</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashLunch, maindata.Chequefields.ChequeLunch)
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="textCenter">{totalCash?.CashLunch}</td>
                <td className="textCenter">{totalCheque?.ChequeLunch}</td>
              </>
            )}
          </tr>
          {/* Dinner */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Dinner</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashDinner, maindata.Chequefields.ChequeDinner)
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="textCenter">{totalCash?.CashDinner}</td>
                <td className="textCenter">{totalCheque?.ChequeDinner}</td>
              </>
            )}
          </tr>
          <tr>
            <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
              Travel
            </td>
            {tableData.map((maindata: ExpenseReportItem) => (
              <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
                {convertDateFormat(maindata?.ExpDate || '')}
              </td>
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  {}
                </td>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  {}
                </td>
              </>
            )}
          </tr>
          {/* Rental Car */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Rental Car</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashCar, maindata.Chequefields.ChequeCar)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashCar}</td>
              <td className="textCenter">{totalCheque?.ChequeCar}</td>
            </>
            )}
          </tr>

          {/* Parking/Tolls */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Parking/Tolls</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashPark, maindata.Chequefields.ChequePark)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashPark}</td>
              <td className="textCenter">{totalCheque?.ChequePark}</td>
            </>
            )}
          </tr>

          {/* Gas */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Gas</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashGas, maindata.Chequefields.ChequeGas)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashGas}</td>
              <td className="textCenter">{totalCheque?.ChequeGas}</td>
            </>
            )}
          </tr>

          {/* Airfare */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Airfare</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashAir, maindata.Chequefields.ChequeAir)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashAir}</td>
              <td className="textCenter">{totalCheque?.ChequeAir}</td>
            </>
            )}
          </tr>

          {/* Sky Caps */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Sky Caps</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashSkycap, maindata.Chequefields.ChequeSkycap)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashSkycap}</td>
              <td className="textCenter">{totalCheque?.ChequeSkycap}</td>
            </>
            )}
          </tr>

          {/* Taxi */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Taxi</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashTaxi, maindata.Chequefields.ChequeTaxi)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashTaxi}</td>
              <td className="textCenter">{totalCheque?.ChequeTaxi}</td>
            </>
            )}
          </tr>
          <tr>
            <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
              Other
            </td>
            {tableData.map((maindata: ExpenseReportItem) => (
              <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
                {convertDateFormat(maindata?.ExpDate || '')}
              </td>
            ))}
            {index === tables.length - 1 && (
              <>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  {}
                </td>
                <td className="tableGreenHeading textCenter boldText underlineHeadingText" style={{ backgroundColor: MainColor }}>
                  {}
                </td>
              </>
            )}
          </tr>
          {/* Entertainment */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Entertainment</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashEnter, maindata.Chequefields.ChequeEnter)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashEnter}</td>
              <td className="textCenter">{totalCheque?.ChequeEnter}</td>
            </>
            )}
          </tr>

          {/* Phone */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Phone</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashPhone, maindata.Chequefields.ChequePhone)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashPhone}</td>
              <td className="textCenter">{totalCheque?.ChequePhone}</td>
            </>
            )}
          </tr>

          {/* Misc */}
          <tr>
            <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Misc</td>
            {tableData.map((maindata: ExpenseReportItem) => (
              expenseData(maindata.Cashfields.CashMisc, maindata.Chequefields.ChequeMisc)
            ))}
            {index === tables.length - 1 && (
            <>
              <td className="textCenter">{totalCash?.CashMisc}</td>
              <td className="textCenter">{totalCheque?.ChequeMisc}</td>
            </>
            )}
          </tr>
        </table>
      ))}
    </div>
  );
}

export default ComponentExpenseMainTable;
