import React from 'react';
import { useSelector } from 'react-redux';
import VesselBayLengthGraph from './vesselBayLengthGraph';
import { RootState } from '../../../redux/rootState';
import { BayLengthDetails } from '../types';

function VesselBayLengthInfoSection() {
  const { reportPdfResponse1, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor } = themePdfColor;

  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>
            Part - 2 Support Bay Length
          </div>
          <div>
            S/N
            {reportPdfResponse1?.JobOrderData?.SerialNumber}
          </div>
        </div>
        <div className="underlineHeadingText textCenter">Vessel Bay Length Information</div>
      </div>
      {reportPdfResponse1?.Support_Bay_Length?.map((data, index: number) => (
        data.map((bayDetails:BayLengthDetails) => (
          <>
            {index > 0 && (<div className="page-break" />)}
            <VesselBayLengthGraph bayDetails={bayDetails} primaryColor={BorderColor} />
          </>
        ))
      ))}
    </div>
  );
}

export default VesselBayLengthInfoSection;
