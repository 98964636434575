/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import timeImg from '../../../../../assets/Icons/timeIcon.svg';
import styles from './index.module.scss';
import { TubeChartPanelNames, onCLickValues } from '../../../utils/tubeChartConstant';
import {
  PanelProps, TubeChartDefectsPayload, TubeChartPanelValues, VanishType,
} from '../../../utils/tubeChartTypes';
import { RootState } from '../../../../../redux/rootState';
import { getTubeChartDefectsStart, setScale, setVanishType } from '../../../redux/tubeChart/tubeChartSlice';

function TubeChartPanel({
  handleUndo, handleSectionChange, tubeCount, totalTubeCount, isUndoDisable, handleScreenshot, isScreenShortLoading,
}:PanelProps) {
  const {
    control, formState: { errors }, setValue, watch,
  } = useForm<TubeChartPanelValues>();

  const {
    selectedSection, vanishType, relativeCord, sectionValues,
    chartList, scale,
  } = useSelector((state:RootState) => state.TubeChartReducer);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const canZoom = watch(TubeChartPanelNames.Zoom);

  const handleVanishChange = (value:string) => {
    dispatch(setVanishType(value as unknown as VanishType));
  };

  useEffect(() => {
    if (chartList.length > 0) {
      setValue(TubeChartPanelNames.Section, selectedSection);
      const currentDetails = chartList.find((details) => details.Panel === selectedSection);
      if (currentDetails) {
        setValue(TubeChartPanelNames.ChartSize, `${currentDetails.NumOfRows} X ${currentDetails.RowLength}`);
      }
    }
  }, [selectedSection, chartList?.length]);

  useEffect(() => {
    setValue(TubeChartPanelNames.TubeCount, String(tubeCount));
    setValue(TubeChartPanelNames.TotalTubeCount, String(totalTubeCount));
  }, [tubeCount, totalTubeCount]);

  const handleZoomChange = (value:boolean) => {
    if (value) {
      dispatch(setScale(scale));
    } else {
      dispatch(setScale(1));
    }
  };

  const handleDefects = () => {
    const jobOrder = searchParams.get('jobOrder');
    const vessel = searchParams.get('vessel');
    const currentDetails = chartList.find((details) => details.Panel === selectedSection);
    if (currentDetails) {
      const payload:TubeChartDefectsPayload = {
        chartId: currentDetails.ChartId || '',
        jobOrderId: String(jobOrder),
        vesselid: String(vessel),
      };
      dispatch(getTubeChartDefectsStart(payload));
    }
  };

  return (
    <div style={{
      background: '#e5f6ee',
      padding: '15px',
      margin: '10px',
      border: '1px solid #00A751',
      borderRadius: '6px',
    }}
    >
      <Grid container spacing={2}>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">Section</label>
            <ComponentSelectInput
              name={TubeChartPanelNames.Section}
              control={control}
              defaultValue={selectedSection}
              errors={errors}
              size="small"
              handleChange={handleSectionChange}
              entries={sectionValues}
              className={styles.whiteBackground}
            />
          </div>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">On Click</label>
            <ComponentSelectInput
              name={TubeChartPanelNames.OnClick}
              control={control}
              errors={errors}
              defaultValue={vanishType}
              handleChange={handleVanishChange}
              size="small"
              entries={onCLickValues}
              className={styles.whiteBackground}
            />
          </div>
        </Grid>
        <Grid item lg={1} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">Chart Size</label>
            <ComponentTextInput
              name={TubeChartPanelNames.ChartSize}
              id={TubeChartPanelNames.ChartSize}
              isDisable
              control={control}
              errors={errors}
              size="small"
              className={styles.whiteBackground}
            />
          </div>
        </Grid>
        <Grid item lg={1} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">Tube Count</label>
            <ComponentTextInput
              name={TubeChartPanelNames.TubeCount}
              id={TubeChartPanelNames.TubeCount}
              isDisable
              control={control}
              errors={errors}
              size="small"
              className={styles.whiteBackground}
            />
          </div>
        </Grid>
        <Grid item lg={1} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">Size</label>
            <TextField
              size="small"
              fullWidth
              onChange={(e) => {
                const { value } = e.target;
                // logic max zoom capacity
                if (Number(value) < 10 && Number(value) >= 1) {
                  dispatch(setScale(Number(value)));
                }
              }}
              defaultValue={1}
              inputProps={{ step: 0.1 }}
              value={scale}
              id={TubeChartPanelNames.Size}
              type="number"
              className={`${styles.whiteBackground}`}
              disabled={!canZoom}
            />
          </div>
        </Grid>
        <Grid item lg={1} md={3} sm={6} xs={6}>
          <div className="div_label_text">
            <label className="no_label_Style" />
            <CustomCheckbox
              name={TubeChartPanelNames.Zoom}
              control={control}
              label="Zoom"
              errors={errors}
              handleChange={handleZoomChange}
            />
          </div>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={6}>
          <div className="div_label_text ">
            <label className="no_label_Style" />
            <img
              src={timeImg}
              className={`${isUndoDisable ? styles.arrowDisable : styles.arrowPointer}`}
              alt="icon"
              height={30}
              width={30}
              onClick={handleUndo}
            />
          </div>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="no_label_Style" />
            <Button disabled={isScreenShortLoading} onClick={handleScreenshot} className="button_save_and_next">
              {isScreenShortLoading ? 'Loading....' : 'Add to Library'}
            </Button>
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="label_Style_Customer">Total Tube Count</label>
            <ComponentTextInput
              name={TubeChartPanelNames.TotalTubeCount}
              id={TubeChartPanelNames.TotalTubeCount}
              isDisable
              control={control}
              errors={errors}
              size="small"
              className={styles.whiteBackground}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={4} sm={6} xs={12} />
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="no_label_Style" />
            {relativeCord.x > 0 && relativeCord.y > 0
            && <div className={styles.textStyle}>{`Row ${relativeCord.x}, Tube ${relativeCord.y}`}</div>}
          </div>
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <div className="div_label_text">
            <label className="no_label_Style" />
            <Button onClick={handleDefects} className="button_save_and_next" disabled={!isUndoDisable}>Display Defects</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default TubeChartPanel;
