import React, { useEffect, useState } from 'react';
// import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../Common/TabsStyles';
import './StyleAddCustomer.scss';
import ComponentMainTab from './Components/main';
import {
  MAIN_TAB, COMMENT_TAB, CONTACT_TAB, JOB_TAB, BIDS_TAB, LABEL_MAIN_TAB, LABEL_COMMENT_TAB,
  LABEL_CONTACT_TAB, LABEL_JOB_TAB, LABEL_BIDS_TAB, CUSTOMER_ID, THEMES_TAB, LABEL_THEMES_TAB,
} from './utils/constants';
import ComponentCommentsTab from './Components/comments';
import CustomerJobOrderDataTable from './Components/ComponentJobOrder/Components/CustomerJobOrderDataTable';
import CustomerBidsDataTable from './Components/ComponentBids';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import CustomerContacts from './Components/contact';
import { RootState } from '../../redux/rootState';
import { getCustomerByIdStart, removeSelectedCustomer, setSelectedTab } from './redux/addCustomerSlice';
import StorageUtils from '../../utils/StorageUtils';
import { GetCustomerByIdPayload } from './utils/types';
import { setLoader } from '../../redux/loaderSlice';
import Themes from './Components/ComponnetThemes';
import { stripCustPrefix } from '../../utils/CommonFunctions';

export default function ScreenAddCustomer() {
  const { selectedTab, isLoading, customerId: isCustomer } = useSelector((state: RootState) => state.addCustomerReducer);
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  const { customerId } = useParams();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    const custId = StorageUtils.getString(CUSTOMER_ID);
    if (custId) {
      const payload: GetCustomerByIdPayload = {
        customerId: custId,
        otherCustomer: false,
      };
      dispatch(getCustomerByIdStart(payload));
    }
    dispatch(setSelectedTab(MAIN_TAB));

    return () => {
      dispatch(removeSelectedCustomer());
    };
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading || isGeneratingPDF));
  }, [isLoading, isGeneratingPDF]);

  useEffect(() => {
    if (customerId) {
      const payload: GetCustomerByIdPayload = {
        customerId,
        otherCustomer: false,
      };
      dispatch(getCustomerByIdStart(payload));
      StorageUtils.setString(CUSTOMER_ID, customerId);
    }
  }, [customerId]);

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case MAIN_TAB:
        return <ComponentMainTab />;
      case COMMENT_TAB:
        return <ComponentCommentsTab />;
      case CONTACT_TAB:
        return <CustomerContacts />;
      case BIDS_TAB:
        return <CustomerBidsDataTable />;
      case THEMES_TAB:
        return <Themes />;
      default:
        return <CustomerJobOrderDataTable />;
    }
  }

  // const handleDownloadPDF = () => {
  //   const inputElement = document.getElementById('customer-main-page');
  //   if (!inputElement) {
  //     return;
  //   }

  //   html2canvas(inputElement).then((canvas) => {
  //     // eslint-disable-next-line new-cap
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed
  //     pdf.save('page.pdf');
  //   });
  // };

  interface Tab {
    tab: number;
    selector: string;
  }

  const handleDownloadPDF = async (): Promise<void> => {
    setIsGeneratingPDF(true);
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', 'a4');

    const tabs: Tab[] = [
      { tab: MAIN_TAB, selector: '#main-tab-content' },
      { tab: COMMENT_TAB, selector: '#comments-tab-content' },
      { tab: CONTACT_TAB, selector: '#contact-tab-content' },
      { tab: THEMES_TAB, selector: '#themes-tab-content' },
      { tab: JOB_TAB, selector: '#job-tab-content' },
      { tab: BIDS_TAB, selector: '#bids-tab-content' },
    ];

    dispatch(setSelectedTab(MAIN_TAB));

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 1500));

    const options = {
      quality: 1,
      scale: 2,
    };

    for (let i = 0; i < tabs.length; i += 1) {
      const { tab, selector } = tabs[i];

      dispatch(setSelectedTab(tab));

      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1200));

      const contentElement = document.querySelector<HTMLElement>(selector); // Use the specific selector for each tab
      if (!contentElement) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-await-in-loop
      const imgData = await domtoimage.toPng(contentElement, options);

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = 210;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (i > 0) {
        pdf.addPage();
      }
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    }
    pdf.save('Customer_Form.pdf');
    dispatch(setSelectedTab(MAIN_TAB));
    setIsGeneratingPDF(false);
  };

  return (
    <>
      <ComponentPageHeader
        subHeading="CUSTOMER"
        fieldId={stripCustPrefix(isCustomer) || ''}
        fieldName="Customer ID"
        printIcon={!!customerId}
        handlePrintClick={handleDownloadPDF}
      />
      <div className="container_job globalTabScroll">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label={LABEL_MAIN_TAB}
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            value={MAIN_TAB}
          />
          <Tab
            label={LABEL_COMMENT_TAB}
            style={selectedTab === COMMENT_TAB ? selectedTabStyles : tabStyles}
            value={COMMENT_TAB}
            disabled={!isCustomer}
          />
          <Tab
            label={LABEL_CONTACT_TAB}
            style={selectedTab === CONTACT_TAB ? selectedTabStyles : tabStyles}
            value={CONTACT_TAB}
            disabled={!isCustomer}
          />
          <Tab
            label={LABEL_THEMES_TAB}
            style={selectedTab === THEMES_TAB ? selectedTabStyles : tabStyles}
            value={THEMES_TAB}
            disabled={!isCustomer}
          />
          {customerId
            && (
              <Tab
                label={LABEL_JOB_TAB}
                style={selectedTab === JOB_TAB ? selectedTabStyles : tabStyles}
                value={JOB_TAB}
                disabled={!isCustomer}
              />
            )}
          {customerId
            && (
              <Tab
                label={LABEL_BIDS_TAB}
                style={selectedTab === BIDS_TAB ? selectedTabStyles : tabStyles}
                value={BIDS_TAB}
                disabled={!isCustomer}
              />
            )}
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>

  );
}
