/* eslint-disable react/no-array-index-key */
// import React from 'react';
// import { BayDetailsTableProps } from '../types';

// function BayDetailsTable({ data, primaryColor }: BayDetailsTableProps) {
//   return (
//     <table className="bordered-table w40 noTableBreak">
//       <div
//         className="tableAfterThickBorderStyle"
//         style={{
//           backgroundColor: primaryColor,
//         }}
//       />
//       <div
//         className="tableBeforeThickBorderStyle"
//         style={{
//           backgroundColor: primaryColor,
//         }}
//       />
//       {data?.map((value, index) => (
//         <tr>
//           <td className="textCenter w50">{`Bay ${index + 1}`}</td>
//           <td className="textCenter w50">
//             {value.toFixed(2)}
//             {' '}
//             &quot;
//           </td>
//         </tr>
//       ))}
//     </table>
//   );
// }

import React from 'react';

// Define the correct type for props
interface BayDetailsTableProps {
  data: number[]; // Array of numbers
  primaryColor?: string; // Color for the table borders
}

function BayDetailsTable({ data, primaryColor }: BayDetailsTableProps) {
  // Helper function to split the data into chunks of 20
  const splitData = (arr: number[], size: number): number[][] => {
    const result: number[][] = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Split data into chunks of 20
  const tables = splitData(data, 20);

  return (
    <>
      {tables.map((tableData, tableIndex) => (
        <div
          key={tableIndex}
          style={{
            display: 'flex',
            gap: 1,
            justifyContent: 'center',
            width: '100%',
            placeItems: 'flex-start',
          }}
        >
          <table className="bordered-table w50 noTableBreak">
            {/* Top and bottom border divs */}
            <div
              className="tableAfterThickBorderStyle"
              style={{
                backgroundColor: primaryColor,
              }}
            />
            <div
              className="tableBeforeThickBorderStyle"
              style={{
                backgroundColor: primaryColor,
              }}
            />
            <tbody>
              {tableData.map((value, index) => (
                <tr key={index}>
                  <td className="textCenter w50">{`Bay ${index + 1 + tableIndex * 20}`}</td>
                  <td className="textCenter w50">
                    {value.toFixed(2)}
                    {' '}
                    &quot;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
}

export default BayDetailsTable;
