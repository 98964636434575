import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../../helper';

function convertCamelCaseToSpaced(str: string) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

function ComponentHazardCheckBox() {
  const { reportPdfResponse4, themePdfColor, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  const entries: [string, boolean | string][] = Object.entries(reportPdfResponse4?.Hazard_Analysis?.[0] || {});
  const chunkedEntries = chunkArray(entries, 18);
  return (
    <>
      {chunkedEntries.length > 0 && (
        <div className="pageHeader">
          <div
            className="underlineHeadingText textCenter"
          >
            {ReportPart1(tableContent, CombineReportEnum.COMBINE_HAZARD_ANALYSIS, PDFReportEnum.PDF_HAZARD_ANALYSIS, 'Hazard Analysis')}
          </div>
        </div>
      )}
      <div>
        {chunkedEntries.map((entryChunk, index) => (
          <>
            <table className="bordered-table w100 mt30">
              <div
                className="tableAfterThickBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              <div
                className="tableBeforeThickBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              {entryChunk?.map(([key, value]: [string, boolean | string]) => (
                <tr>
                  <td
                    className=" tableGreenHeading textCenter boldText"
                    style={{
                      height: '45px',
                      backgroundColor: MainColor,
                    }}
                  >
                    {convertCamelCaseToSpaced(key)}
                  </td>
                  <td style={{
                    display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '45px',
                  }}
                  >
                    <input
                      type="checkbox"
                      checked={!!(value)}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </td>
                </tr>
              ))}
            </table>
            {index <= chunkedEntries.length - 1
          && <div className="page-break" />}
          </>
        ))}
      </div>
    </>
  );
}

export default ComponentHazardCheckBox;
