import { MainFormValues, SiteDetailsByIdResult } from './types';

export enum SiteForm {
  smc = 'smc',
  site = 'site',
  contact = 'contact',
  address = 'address',
  addressOptional = 'addressOptional',
  country = 'country',
  zip = 'zip',
  state = 'state',
  city = 'city',
  SITE_OTHER_CITY = 'siteOtherCity',
  code = 'code',
  phone = 'phone',
  cell = 'cell',
  safetyCompanyName = 'safetyCompanyName',
  siteType = 'siteType',
  note = 'note',
  smcId = 'smcId',
}

export const assignSiteDetailsValues = (selectedSmcDetails: SiteDetailsByIdResult, setValue: SetValue) => {
  setValue(SiteForm.smc, selectedSmcDetails?.SMC);
  setValue(SiteForm.site, selectedSmcDetails?.SiteName);
  setValue(SiteForm.contact, selectedSmcDetails?.Contact);
  setValue(SiteForm.address, selectedSmcDetails?.Address);
  setValue(SiteForm.addressOptional, selectedSmcDetails?.OptionalAddress);
  setValue(SiteForm.country, selectedSmcDetails?.CountryCode);
  setValue(SiteForm.zip, selectedSmcDetails?.Pincode);
  setValue(SiteForm.state, selectedSmcDetails?.State);
  setValue(SiteForm.city, selectedSmcDetails?.City);
  setValue(SiteForm.code, selectedSmcDetails.PhoneCode);
  setValue(SiteForm.phone, selectedSmcDetails?.Phone);
  setValue(SiteForm.cell, selectedSmcDetails?.Cell);
  setValue(SiteForm.safetyCompanyName, selectedSmcDetails?.SmcName);
  setValue(SiteForm.siteType, selectedSmcDetails?.SiteTypeId);
  setValue(SiteForm.note, selectedSmcDetails?.Note);
  setValue(SiteForm.smcId, selectedSmcDetails?.SmcId);
};

export function editSitePayload(values: MainFormValues, siteId: string) {
  return {
    SiteId: siteId,
    SMC: values.smc,
    SiteName: values.site,
    Contact: values.contact,
    Address: values.address,
    OptionalAddress: values.addressOptional,
    CountryCode: values.country,
    Pincode: values.zip || 'TAI0000',
    CityName: values.city === 'Other' ? values?.siteOtherCity : values.city || 'NAN',
    IsOtherCity: values?.city === 'Other',
    StateName: values.state || 'NAN',
    PhoneCode: values.code,
    Phone: values.phone,
    Cell: values.cell,
    SiteTypeId: values.siteType,
    SmcId: values.smcId,
    Note: values.note,
  };
}
