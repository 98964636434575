/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, switchMap, takeUntil,
} from 'rxjs';

import { AxiosError } from 'axios';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest,
} from '../../../Common/NetworkOps';
import { GetById, GetListingPayload, ListingResponse } from '../../../utils/type';
import {
  DeleteStripChartPayload,
  GetStripChartListingPayload, RequestData, StripChartData, CreateStripChartType,
  DeletePhaseChartPayload, PhaseChart, UpdatePhaseChartType, VesselInformationRes,
  UpdateStripChart, AsmeByIdResponse,
  UpdateAsme,
  AnalystReportsType,
  AnalystDataPayload,
} from '../utils/type';
import {
  createStripChartFailure, createStripChartStart, createStripChartSuccess,
  createPhaseChartFailure, deleteStripChartFailure, deleteStripChartStart, deleteStripChartSuccess,
  createPhaseChartStart, getStripChartByIdFailure, getStripChartByIdStart,
  createPhaseChartSuccess, getStripChartByIdSuccess, getStripChartFailure, getStripChartStart, getStripChartSuccess, updateStripChartFailure, updateStripChartStart, updateStripChartSuccess,
  deletePhaseChartFailure,
  deletePhaseChartStart,
  deletePhaseChartSuccess,
  deleteVesselFailure, deleteVesselStart, getPhaseChartByIdFailure, getPhaseChartByIdStart,
  getPhaseChartByIdSuccess, getPhaseChartFailure, getPhaseChartStart, getPhaseChartSuccess,
  getVesselInformationStart, getVesselInformationSuccess, updatePhaseChartFailure, updatePhaseChartStart,
  updatePhaseChartSuccess, VesselInformationActions, getAsmeByIdFailure, getAsmeByIdStart, getAsmeByIdSuccess,
  updateAsmeStart,
  updateAsmeSuccess,
  updateAsmeFailure,
  onCopyReportsStart,
  onCopyReportsSuccess,
  onCopyReportsFailure,
  getVesselInformationFailure,
} from './sliceVesselInformation';

async function getVesselInfo(data: GetListingPayload): Promise<ListingResponse<VesselInformationRes>> {
  const url = `${Config.vesselInformation.getVesselInformation}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<VesselInformationRes>>(url);
  return result.data;
}
export const getVesselInfoEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getVesselInformationStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetListingPayload) => from(getVesselInfo(data)).pipe(
    map((res: ListingResponse<VesselInformationRes>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getVesselInformationSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getVesselInformationFailure();
    }),
    takeUntil(action$.pipe(filter(getVesselInformationStart.match))),
    catchError((error) => of(getVesselInformationFailure(error))),
  )),
);
async function deleteVessel(vesselId: string): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.deleteVessel}?VesselId=${vesselId}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deleteVesselEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(deleteVesselStart.type),
  map((x) => x.payload),
  switchMap((data: string) => from(deleteVessel(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return deleteVesselStart(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return deleteVesselFailure();
    }),
    takeUntil(action$.pipe(filter(deleteVesselStart.match))),
    catchError((error) => of(deleteVesselFailure(error))),
  )),
);
// Get Strip Chart data
async function getStripChartList(data: GetStripChartListingPayload): Promise<ListingResponse<StripChartData>> {
  const url = `${Config.vesselInformation.stripChartList}?JobOrder=${data.jobOrder}&VesselId=${data.vesselId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<StripChartData>>(url);
  return result.data;
}
export const getStripChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getStripChartStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetStripChartListingPayload) => from(getStripChartList(data)).pipe(
    map((res: ListingResponse<StripChartData>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getStripChartSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getStripChartFailure();
    }),
    takeUntil(action$.pipe(filter(getStripChartStart.match))),
    catchError((error) => of(getStripChartFailure(error))),
  )),
);
// Get Strip Chart By ID
async function getStripChartDataById(data: { id: string }): Promise<GetById<StripChartData>> {
  const url = `${Config.vesselInformation.stripChartById}/${data.id}`;
  const result = await makeGetRequest<GetById<StripChartData>>(url);
  return result.data;
}
export const getStripChartByIdEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getStripChartByIdStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: { id: string }) => from(getStripChartDataById(data)).pipe(
    map((res: GetById<StripChartData>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getStripChartByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getStripChartByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getStripChartByIdStart.match))),
    catchError((error) => of(getStripChartByIdFailure(error))),
  )),
);
// ---Update Strip Chart
async function updateStripChart(data:StripChartData): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.updateStripChartUrl}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}
export const UpdateStripChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(updateStripChartStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, getListPayload }:UpdateStripChart) => from(updateStripChart(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payloadAPI = {
          jobOrder: getListPayload.jobOrder,
          vesselId: getListPayload.vesselId,
          page: getListPayload.page,
          rowsPerPage: getListPayload.rowsPerPage,
          searchQuery: getListPayload.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (updateStripChartSuccess(), getStripChartStart(payloadAPI));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateStripChartFailure();
    }),
    takeUntil(action$.pipe(filter(updateStripChartStart.match))),
    // catchError((error) => of(updateStripChartFailure(error))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.Result as string;
      of(showErrorToaster(errorMessage));
      return of(updateStripChartFailure());
    }),
  )),
);
// ---Create Strip Chart
async function createStripChart(data:RequestData): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.addStripChartUrl}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const CreateStripChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(createStripChartStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, getListPayload }: CreateStripChartType) => from(createStripChart(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        const payloadAPI = {
          jobOrder: getListPayload.jobOrder,
          vesselId: getListPayload.vesselId,
          page: getListPayload.page,
          rowsPerPage: getListPayload.rowsPerPage,
          searchQuery: getListPayload.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (createStripChartSuccess(), getStripChartStart(payloadAPI));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createStripChartFailure();
    }),
    takeUntil(action$.pipe(filter(createStripChartStart.match))),
    catchError((error) => of(createStripChartFailure(error))),
  )),
);
async function deleteStripChart(data:DeleteStripChartPayload): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.deleteStripChartUrl}/${data.id}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deleteStripChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(deleteStripChartStart.type),
  map((x) => x.payload),
  switchMap((data:DeleteStripChartPayload) => from(deleteStripChart(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          jobOrder: data.jobOrder,
          vesselId: data.vesselId,
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (deleteStripChartSuccess(), getStripChartStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return deleteStripChartFailure();
    }),
    takeUntil(action$.pipe(filter(deleteStripChartStart.match))),
    catchError((error) => of(deleteStripChartFailure(error))),
  )),
);

// Get Phase Chart Listing Data
async function getPhaseChartData(data: GetListingPayload): Promise<ListingResponse<PhaseChart>> {
  const url = `${Config.vesselInformation.getPhaseChart}?JobOrder=${data.jobOrder}&VesselId=${data.vesselId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<PhaseChart>>(url);
  return result.data;
}
export const getPhaseChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getPhaseChartStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetListingPayload) => from(getPhaseChartData(data)).pipe(
    map((res: ListingResponse<PhaseChart>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getPhaseChartSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getPhaseChartFailure();
    }),
    takeUntil(action$.pipe(filter(getPhaseChartStart.match))),
    catchError((error) => of(getPhaseChartFailure(error))),
  )),
);
// Get Phase Chart Listing Data
async function getPhaseChartDataById(data: { id: string }): Promise<GetById<PhaseChart>> {
  const url = `${Config.vesselInformation.getPhaseChartById}/${data.id}`;
  const result = await makeGetRequest<GetById<PhaseChart>>(url);
  return result.data;
}
export const getPhaseChartByIdEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getPhaseChartByIdStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: { id: string }) => from(getPhaseChartDataById(data)).pipe(
    map((res: GetById<PhaseChart>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getPhaseChartByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getPhaseChartByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getPhaseChartByIdStart.match))),
    catchError((error) => of(getPhaseChartByIdFailure(error))),
  )),
);
async function deletePhaseChart(data:DeletePhaseChartPayload): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.deletePhaseChart}/${data.id}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deletePhaseChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(deletePhaseChartStart.type),
  map((x) => x.payload),
  switchMap((data:DeletePhaseChartPayload) => from(deletePhaseChart(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          jobOrder: data.jobOrder,
          vesselId: data.vesselId,
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (deletePhaseChartSuccess(), getPhaseChartStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return deletePhaseChartFailure();
    }),
    takeUntil(action$.pipe(filter(deletePhaseChartStart.match))),
    catchError((error) => of(deletePhaseChartFailure(error))),
  )),
);
async function updatePhaseChart(data:PhaseChart): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.updatePhaseChart}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}
export const UpdatePhaseChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(updatePhaseChartStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, getListPayload }: UpdatePhaseChartType) => from(updatePhaseChart(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payloadAPI = {
          jobOrder: getListPayload.jobOrder,
          vesselId: getListPayload.vesselId,
          page: getListPayload.page,
          rowsPerPage: getListPayload.rowsPerPage,
          searchQuery: getListPayload.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (updatePhaseChartSuccess(), getPhaseChartStart(payloadAPI));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updatePhaseChartFailure();
    }),
    takeUntil(action$.pipe(filter(updatePhaseChartStart.match))),
    catchError((error) => of(updatePhaseChartFailure(error))),
  )),
);

async function createPhaseChart(data:PhaseChart): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.createPhaseChart}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const CreatePhaseChartEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(createPhaseChartStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, getListPayload }: UpdatePhaseChartType) => from(createPhaseChart(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payloadAPI = {
          jobOrder: getListPayload.jobOrder,
          vesselId: getListPayload.vesselId,
          page: getListPayload.page,
          rowsPerPage: getListPayload.rowsPerPage,
          searchQuery: getListPayload.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (createPhaseChartSuccess(), getPhaseChartStart(payloadAPI));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createPhaseChartFailure();
    }),
    takeUntil(action$.pipe(filter(createPhaseChartStart.match))),
    catchError((error) => of(createPhaseChartFailure(error))),
  )),
);

async function getAsmeById(asmeId: string): Promise<GetById<AsmeByIdResponse>> {
  const url = `${Config.vesselInformation.asme.getAmseById}/${asmeId}`;
  const result = await makeGetRequest<GetById<AsmeByIdResponse>>(url);
  return result.data;
}

export const getAsmeByIdEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(getAsmeByIdStart.type),
  map((x) => x.payload),
  switchMap((data: string) => from(getAsmeById(data)).pipe(
    map((res: GetById<AsmeByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAsmeByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAsmeByIdFailure();
    }),

    takeUntil(action$.pipe(filter(getAsmeByIdStart.match))),
    catchError((error) => of(getAsmeByIdFailure(error))),
  )),
);

async function updateAsme(payload: UpdateAsme): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.asme.getAmseById}`;
  const result = await makePutRequest<GetById<string>>(url, payload);
  return result.data;
}

export const updateAsmeEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(updateAsmeStart.type),
  map((x) => x.payload),
  switchMap((data: UpdateAsme) => from(updateAsme(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateAsmeSuccess(), getAsmeByIdStart(`${data.AsmeId}`));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateAsmeFailure());
    }),

    takeUntil(action$.pipe(filter(updateAsmeStart.match))),
    catchError((error) => of(updateAsmeFailure(error))),
  )),
);

async function analystReports(data:AnalystDataPayload): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.analystReports}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const AnalystReportsEpic = (action$: Observable<VesselInformationActions>) => action$.pipe(
  ofType(onCopyReportsStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, handleData }: AnalystReportsType) => from(analystReports(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        handleData();
        return onCopyReportsSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onCopyReportsFailure();
    }),
    takeUntil(action$.pipe(filter(onCopyReportsStart.match))),
    catchError((error) => of(onCopyReportsFailure(error))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onCopyReportsFailure());
    }),
  )),
);
