import React, { useEffect } from 'react';
import {
  Grid, Card, FormControlLabel, Radio, RadioGroup, FormControl,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../../../Common/RequiredField';
import { UnitInfoFormNames } from '../../utils/constants';
import { RootState } from '../../../../../redux/rootState';
import { UnitFormComponentProps } from '../../utils/type';
import { pctRegex, RegexTwoWith2D } from '../../../../../utils/regex';
import { getSerialNoStart, setIsUserInteracting } from '../../redux/unitInfoSlice';

export default function UnitFormComponent({
  control, handleChange, watch, setValue,
}: UnitFormComponentProps) {
  const {
    unitMaster, machineType, SerialNo,
  } = useSelector((state:RootState) => state.unitInfoReducer);

  const testType = watch(UnitInfoFormNames.testType);
  const spotTest = watch(UnitInfoFormNames.spotTest);
  const dispatch = useDispatch();
  const NewSerialNo = SerialNo?.split('=')[1]?.trim();

  const handlePCTChange = (val:boolean) => {
    handleChange();
    if (!val) {
      setValue(UnitInfoFormNames.pct, '');
    }
    dispatch(setIsUserInteracting(true));
  };

  useEffect(() => {
    dispatch(getSerialNoStart());
  }, []);

  useEffect(() => {
    setValue(UnitInfoFormNames.serialNumber, NewSerialNo);
  }, [NewSerialNo]);

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Type of Test
              <RequiredFiled />
            </div>
            <div className="double_checkbox">
              <CustomCheckbox
                control={control}
                name={UnitInfoFormNames.safetyEqRequired}
                handleChange={handleChange}
                label="Safety Equipment Required"
              />
              <CustomCheckbox
                control={control}
                name={UnitInfoFormNames.safetyCharges}
                label="Safety Charges"
                handleChange={() => {
                  handleChange();
                  dispatch(setIsUserInteracting(true));
                }}
                defaultValue={false}
              />
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={9} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">&nbsp;</label>
                  <Controller
                    name={UnitInfoFormNames.testType}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <FormControl>
                        <RadioGroup
                          row
                          value={testType}
                          key={testType}
                          defaultValue={UnitInfoFormNames.eddyCurrentTest}
                          onChange={(event) => {
                            onChange(event);
                            handleChange();
                            dispatch(setIsUserInteracting(true));
                          }}
                        >
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoFormNames.eddyCurrentTest}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Eddy Current Test</span>}
                            />
                          </div>
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoFormNames.fluxLeakagetest}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Flux Leakage Test</span>}
                            />
                          </div>
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoFormNames.absTest}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Abs / Diff Test</span>}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </div>
              </Grid>
              {
                testType === UnitInfoFormNames.fluxLeakagetest ? (
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">
                        OD of Tubes
                        {' '}
                        <RequiredFiled />
                        {' '}
                      </label>
                      <ComponentTextInput
                        control={control}
                        required
                        type="number"
                        name={UnitInfoFormNames.odTubes}
                        regEx={RegexTwoWith2D}
                        id={UnitInfoFormNames.odTubes}
                        size="small"
                        handleChange={() => {
                          handleChange();
                          dispatch(setIsUserInteracting(true));
                        }}
                      />
                    </div>
                  </Grid>
                ) : ''
              }
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Unit Details
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Machine Type
                    {' '}
                    <RequiredFiled />
                    {' '}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    defaultValue=""
                    name={UnitInfoFormNames.machineType}
                    required
                    size="small"
                    handleChange={() => {
                      handleChange();
                      dispatch(setIsUserInteracting(true));
                    }}
                    entries={machineType}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Location (Building)
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={UnitInfoFormNames.location}
                    id={UnitInfoFormNames.location}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 35 }}
                    className="nonCapitalizeField"
                    // regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,35}$/}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Manufacturer
                    {' '}
                    <RequiredFiled />
                    {' '}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoFormNames.manufacturer}
                    defaultValue=""
                    required
                    size="small"
                    handleChange={handleChange}
                    entries={unitMaster?.Manufacture || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Model
                    {' '}
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={UnitInfoFormNames.model}
                    inputProps={{ maxLength: 10 }}
                    handleChange={handleChange}
                    // regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,10}$/}
                    id={UnitInfoFormNames.model}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Type</label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoFormNames.type}
                    handleChange={handleChange}
                    defaultValue=""
                    size="small"
                    entries={unitMaster?.Type || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Style</label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoFormNames.style}
                    defaultValue=""
                    size="small"
                    handleChange={handleChange}
                    entries={unitMaster?.Style || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Serial #
                    {/* {' '}
                    <RequiredFiled /> */}
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={UnitInfoFormNames.serialNumber}
                    id={UnitInfoFormNames.serialNumber}
                    defaultValue={NewSerialNo}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 10 }}
                    handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">PO #</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={UnitInfoFormNames.PONumber}
                    defaultValue=""
                    inputProps={{ maxLength: 10 }}
                    // regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,10}$/}
                    id={UnitInfoFormNames.PONumber}
                    handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="double_checkbox_container">
            <CustomCheckbox handleChange={handleChange} name={UnitInfoFormNames.leak} label="Leak" control={control} />
            <CustomCheckbox handleChange={handleChange} name={UnitInfoFormNames.outside} label="Outside" control={control} />
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Job Time </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={UnitInfoFormNames.jobTime}
                    defaultValue=""
                    id={UnitInfoFormNames.jobTime}
                    handleChange={handleChange}
                    regEx={/^[A-Za-z ]{1,10}$/}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Spot Test
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="unit_test_style">
                  <div>
                    <label>&nbsp;</label>
                    <CustomCheckbox
                      defaultValue={false}
                      name={UnitInfoFormNames.spotTest}
                      handleChange={handlePCTChange}
                      label="Spot Test"
                      control={control}
                    />
                  </div>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Pct.
                      {spotTest && <RequiredFiled />}
                    </label>
                    <ComponentTextInput
                      control={control}
                      required={spotTest}
                      name={UnitInfoFormNames.pct}
                      defaultValue=""
                      type="number"
                      id={UnitInfoFormNames.pct}
                      regEx={pctRegex}
                      isDisable={!spotTest}
                      handleChange={() => {
                        handleChange();
                        dispatch(setIsUserInteracting(true));
                      }}
                      size="small"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </>
  );
}
