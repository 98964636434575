/* eslint-disable @typescript-eslint/ban-ts-comment */
import { combineEpics } from 'redux-observable';
import {
  epicAddSmcDetails,
  epicGetSmcTabUserList,
  epicGetSmcTabCustomerList,
  epicUnlinkSmcCustomer,
  epicUnlinkSmcUser,
  epicGetSmcTabSitesList,
  epicUnlinkSmcSite,
  epicGetUsersList,
  epicAddSmcUserDetails,
  epicUpdateSmc,
  epicGetSmcTabUserListById,
} from '../Screens/ScreenAddSmc/Redux/epicAddSmc';
import {
  epicLogin,
  epicSendOtp,
} from '../Screens/Login/ScreenLogIn/redux/epicLogin';
import {
  epicForgotPassword,
  epicSendOtpForgot,
} from '../Screens/Login/ScreenForgotPassword/redux/epicForgotPassword';
import { epicOtpVerify } from '../Screens/Login/ScreenOtpVerify/redux/epicOtpVerify';
import {
  epicGetUserProfile,
  epicUpdateImageData,
  epicUpdateUserProfile,
} from '../Screens/Profile/Redux/epicMyProfile';
import { epicLogout } from '../Screens/Logout/redux/epicLogout';
import { epicResetPassword } from '../Screens/Login/ScreenReset/redux/epicReset';
import {
  epicGetSmcUserList,
  epicDeleteSmc,
} from '../Screens/smc/Redux/epicSmc';
import {
  generateInvoiceEpic, getMasterDataEpic, getPincodeDetailsEpic, getCitySuggestionEpic,
  getRoleMasterDataEpic,
  getReportTypeMasterDataEpic,
  getCodeMasterDataEpic,
} from './masters/masterEpic';
import {
  getCustomersEpic,
  deleteCustomerEpic,
} from '../Screens/customer/redux/customerEpic';
import {
  addCustomersEpic,
  addContactEpic,
  getContactsEpic,
  getCustomerByIdEpic,
  editCustomersEpic,
  deleteContactEpic,
  getContactByIdEpic,
  editContactEpic,
  addCommentEpic,
  updateCommentEpic,
  epicGetBidsList,
  epicDeleteBid,
  epicUpdateLogoData,
  addThemeEpic,
  getBillingPinCodeDetailsEpic,
  getCustomerJobOrderEpic,
} from '../Screens/addCustomer/redux/addCustomerEpic';
import {
  epicAddFreon,
  epicEditFreon,
  epicUnitPrice,
} from '../Screens/Kits/FreonCylinder/AddFreonCylinder/redux/epicAddFreonCylinder';
import {
  epicDeleteFreon,
  epicFreonById,
  epicFreonList,
} from '../Screens/Kits/FreonCylinder/FreonCylinderList/redux/epicListFreonCylinder';
import {
  getGeneralSitesEpic,
  getSiteByIdEpic,
  getAssociatedSitesEpic,
  getSiteTypesEpic,
  addSiteEpic,
  addJobEpic,
  addJobCommentEpic,
  getJobByIdEpic,
  updateJobEpic,
  getBidListingEpic,
  deleteBidEpic,
  epicGetJobBidsList,
} from '../Screens/Job/jobStarter/addJob/redux/addJobEpic';
import {
  getJobsEpic,
  deleteJobEpic,
} from '../Screens/Job/jobStarter/redux/jobEpic';
import { getDashboardEpic } from '../Screens/ComponentNavBar/redux/dashboardEpic';
import {
  getAnalystsEpic,
  createBidEpic,
  getAnalystCityEpic,
  getBidByIdEpic,
  getUnitListingEpic,
  getDriverExpenseEpic,
  getBidPricingEpic,
  getNearestAnalystEpic,
  getCustomerAnalystEpic,
  updateBidEpic,
  associateUnitsEpic,
  deleteUnitEpic,
  getJobUnitListingEpic,
} from '../Screens/Job/ScreenBidWorkSheetJob/redux/bidWorksheetEpic';

import {
  epicGetKitPriceList,
  epicUpdateKitPrices,
} from '../Screens/System/KitPrices/Redux/epicKitPrice';

import {
  epicGetSystemPriceList,
  epicUpdateSystemPriceLists,
} from '../Screens/System/PriceList/Redux/epicPriceList';
import {
  epicDeleteMarkups,
  epicGetSystemMarkup,
  epicUpdateMarkups,
  epicAddMarkups,
} from '../Screens/System/Markups/Redux/epicMarkups';
import {
  addOilKitEpic,
  addOilRangeDeleteEpic,
  addOilRangeEpic,
  editOilKitEpic,
  epicOilKitsUnitPrice,
  getDetailsByIdEpic,
  getOilKitRangeListEpic,
} from '../Screens/Kits/OilKits/ScreenAddOilKits/redux/addOilKitsEpic';
import {
  deleteOilKitsEpic,
  getOilKitsListEpic,
  getOilKitsSearchEpic,
} from '../Screens/Kits/OilKits/ScreenOilKits/redux/oilKitsEpic';
import {
  deleteAnalystEpic,
  getAnalystDataEpic,
  getCarrierDataEpic,
  getTeritoryDataEpic,
} from '../Screens/AnalystScreen/AnalystListScreen/redux/AnalystListEpic';
import {
  addAnalystDataEpic,
  addAnalystLeaveEpic,
  deleteAnalystLeaveEpic,
  getAnalystBasePinCodeEpic,
  getAnalystDataByIDEpic,
  getAnalystLeaveDataEpic,
  getAnalystMasterLeaveDataEpic,
  getAnalystPinCodeEpic,
  getAnalystSecondaryBasePinCodeEpic,
  updateAnalystDataEpic,
} from '../Screens/AnalystScreen/AnalystAddScreen/redux/AddAnalystEpic';

import { epicChangePassword } from '../Screens/ChangePassword/Redux/epicChangePwd';
import {
  createUnitEpic,
  getVesselMasterEpic,
  getUnitMasterEpic,
  getUnitPriceEpic,
  getUnitByIdEpic,
  updateUnitEpic,
  getSerialNumberEpic,
} from '../Screens/Job/ScreenUnitInformation/redux/unitInfoEpic';
import {
  epicCreateEquipment,
  epicEquipmentMaster,
  epicUnitMaster,
  epicGetEquipmentList,
} from '../Screens/Job/ScreenTestEquipmentRequest/redux/epicTestEquipments';
import {
  getJobScheduleListDataEpic,
  getJobScheduleUnitDataEpic,
  getJobTravelDataEpic,
  onAddScheduleEpic,
  onUpdateScheduleEpic,
  onAddAccommodationScheduleEpic,
  onUpdateAccommodationScheduleEpic,
  onDeleteAccommodationScheduleEpic,
  getHotelListDataEpic,
  onCreateJobScheduleEpic,
  getCommentDataEpic,
  onUpdateSiteDataEpic,
  getTravelDataByIdListEpic,
  onReadyToSendStartListEpic,
  getAssociatedVesselsEpic,
  getGeneralVesselsEpic,
  AddJobReportEpic,
  getReportDataByIdEpic,
  deleteVesselReportsEpic,
} from '../Screens/JobScheduling/UnscheduledJobs/addJob/redux/jobScheduleEpic';
import {
  epicAddTestEndList, epicDeleteTestEndList, epicGetTestEndList, epicUpdateTestEndList,
} from '../Screens/Lists/TestEndLists/redux/epicTestEndList';
import {
  epicAddSupportList, epicDeleteSupportList, epicGetSupportList, epicUpdateSupportList,
} from '../Screens/Lists/SupportLists/redux/epicSupportList';
import {
  epicAddManufacturerList, epicDeleteManufacturerList, epicGetManufacturerList, epicUpdateManufacturerList,
} from '../Screens/Lists/ManufacturerLists/redux/epicManufacturerList';
import {
  epicAddMaterialList, epicDeleteMaterialList, epicGetMaterialList, epicUpdateMaterialList,
} from '../Screens/Lists/MaterialLists/redux/epicMaterialList';
import {
  epicAddVesselList, epicDeleteVesselList, epicGetVesselList, epicUpdateVesselList,
} from '../Screens/Lists/VesselLists/redux/epicVesselList';
import {
  epicAddTubeList, epicDeleteTubeList, epicGetTubeList, epicUpdateTubeList,
} from '../Screens/Lists/TubeList/redux/epicTubeList';
import {
  epicAddTypeList, epicDeleteTypeList, epicGetTypeList, epicUpdateTypeList,
} from '../Screens/Lists/UnitTypeLists/redux/epicTypeList';
import {
  epicAddStyleList, epicDeleteStyleList, epicGetStyleList, epicUpdateStyleList,
} from '../Screens/Lists/UnitStyleLists/redux/epicStyleList';
import {
  epicAddReportList, epicDeleteReportList, epicGetReportList, epicUpdateReportList,
} from '../Screens/Lists/ReportList/redux/epicReportList';
import {
  epicDeleteSite, epicGetCustomersList, epicGetSiteDetailsById, epicGetSiteTypeData, epicGetSitesList, epicUpdateUnit,
} from '../Screens/Sites/redux/epicSites';
import { epicCreateCalibrationProcedure, epicGetCalibrationProcedureData } from '../Screens/Utilities/calibrationProcedure/Redux/epicCalibration';
import {
  epicCreateRecommendationCodesData,
  epicDeleteRecommendationCodes,
  epicGetRecommendationCodesData,
} from '../Screens/Utilities/recommendationCodes/Redux/epicRecommendationCodes';
import { epicGetBidPdfDetails } from '../Screens/ScreenBidPdf/redux/epicBidPdf';
import {
  getMasterEventEpic, createEventEpic, getAllEventsEpic, updateEventEpic, deleteEventEpic,
  createBookMarkEpic, getAllBookmarksEpic, updateBookMarkEpic, getJobEventDetailsEpic,
  createPublicHolidayEpic, getPublicHolidaysEpic,
} from '../Screens/calender/redux/calenderEpic';
import {
  epicCreateDefectCode, epicDeleteDefectCode, getColorMasterDataEpic, getTreeDataEpic,
} from '../Screens/Utilities/AnalystDefectMenu/redux/epicDefectCode';
import {
  epicAddDefaultList, epicDeleteDefaultList, epicGetDefaultList, epicUpdateDefaultList, getTypeStyleMasterEpic,
} from '../Screens/Lists/DefaultTypeStyleList/redux/epicDefaultTypeList';
import {
  addEquipmentEpic,
  deleteInventoryEpic, getInventoryDataEpic, getInventoryIssueHistoryEpic,
  getInventoryMasterDataEpic, getUpcomingJobsEpic, issueProductEpic, returnProductEpic,
} from '../Screens/InventoryManagement/InventoryLandingPage/redux/inventoryEpic';
import {
  addEquipmentByAdminEpic,
  createEquipmentRequestEpic,
  equipmentRequestDataEpic,
  inventoryEquipmentDeleteEpic,
  inventoryEquipmentDetailsByIdEpic,
  inventoryEquipmentHistoryEpic,
  inventoryEquipmentNeededMaster,
  inventoryEquipmentRequestMaster,
  inventoryEquipmentRequestStatusMaster,
} from '../Screens/InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestEpic';
import {
  epicAddDefectCodeList, epicDeleteDefectCodeList, epicGetDefectCodeList, epicUpdateDefectCodeList,
} from '../Screens/Utilities/DefectCode/redux/epicDefectCodeList';
import {
  epicAddEquipmentCategoryList, epicDeleteEquipmentCategoryList, epicGetEquipmentCategoryList, epicUpdateEquipmentCategoryList,
} from '../Screens/Lists/EquipmentCategoryLists/redux/epicEquipmentCategoryList';
import {
  getDataForPrintPdfEpic,
  getProductDetailsByIdEpic, getRequestDataByIdEpic, sendEquipmentEpic,
} from '../Screens/InventoryManagement/SendEquipmentScreen/redux/sendEquipmentScreenEpic';

import {
  getOilReportsEpic, getOilReportByIdEpic, updateOilReportEpic, uploadExcelEpic,
  getExcelDataEpic, getAllUnitsSerialNumberEpic, getAllComponentSerialNumberEpic,
  getOilReportPdfEpic,
  replaceUnitCompEpic,
  getCustomerContactListEpic, deleteOilReportEpic,
} from '../Screens/oilReport/redux/oilReportEpic';
import {
  epicOnActivateDeactivateUserManagementData, epicOnCreateUserManagementData, epicOnGetUserManagementData,
} from '../Screens/UserManagement/redux/epicUserManagement';
import {
  epicGetBoardPositionData, epicGetFilteredAnalystData, epicOnCreateZone, epicOnRemoveAnalyst, epicOnRowOrderingAnalyst,
} from '../Screens/boardPosition/redux/epicBoardPosition';
import {
  deleteJobOrderEpic, getJobOrderDataByIdEpic, getJobOrderDataEpic, getUnitPriceJobOrderEpic, JobOrderMoveWorkspaceEpic, updateJobOrderEpic,
} from '../Screens/jobOrder/redux/epicJobOrder';

import {
  getVesselInfoEpic, deleteVesselEpic, getStripChartEpic, getStripChartByIdEpic, UpdateStripChartEpic, CreateStripChartEpic,
  getPhaseChartEpic, getPhaseChartByIdEpic,
  deletePhaseChartEpic, getAsmeByIdEpic,
  UpdatePhaseChartEpic, updateAsmeEpic,
  CreatePhaseChartEpic,
  deleteStripChartEpic,
  AnalystReportsEpic,
} from '../Screens/VesselInformation/redux/vesselInformationEpic';

import {
  getInspectionSummaryByIdEpic, getInspectionSummaryEpic,
  updateInspectionSummaryEpic,
} from '../Screens/inspectionSummary/redux/inspectionSummaryEpic';
import {
  AddTimeDateEpic,
  CreateVesselDefectEpic, deleteVesselDefectEpic, getDefectByIdEpic, getDefectDropDownDataEpic, getVesselDefectInfoEpic,
  InsertDefectTimeEpic,
  SearchReplaceDefectEpic,
  UpdateVesselDefectEpic,
} from '../Screens/VesselInformation/redux/DefectRedux/vesselDefectEpic';
import {
  getRecommendationsEpic, getRecmByIdEpic, getStartupTextEpic, getFollowupTextEpic,
  updateRecomEpic,
  getInspectionIndicationByIdEpic,
} from '../Screens/ScreenRecommendations/redux/recommendationEpic';
import {
  deleteExpenseReportEpic, deleteScannedReceiptsEpic, epicAddScannedReceiptsData, getExpenseReportByIdEpic, getExpenseReportDataEpic,
  getScannedReceiptsEpic, updateExpenseReportEpic,
} from '../Screens/expenseReports/redux/expenseReportEpic';
import { getDataSheetEditEpic, getDataSheetEpic } from '../Screens/datasheets/redux/epicDataSheet';
import { getBaylengthEpic, updateBayLengthEpic } from '../Screens/VesselInformation/redux/baylength/baylengthEpic';
import {
  getMaterialDataEpic, getSupportDataEpic, getTestEndDataEpic, getTubeTypeDataEpic, getVesselDataEpic, getVesselMainByIdEpic, updateVesselMainEpic,
} from '../Screens/VesselInformation/redux/VesselMainRedux/vesselMainEpic';
import {
  getAllVesselEpic, getPhotoByIdEpic, getPhotosDataEpic, updatePhotoEditEpic, updatePhotoEpic,
} from '../Screens/photos/redux/photosEpic';
import {
  getHazardAnalysisByIdEpic,
  getHazardAnalysisDataEpic, updateHazardAnalysisEpic,
} from '../Screens/ScreenHazardAnalysis/redux/hazardAnalysisEpic';
import {
  updateTubeChartEpic, getTubeChartsEpic, getTubeChartByIdEpic, getTubeChartDefectsEpic,
  updateTubeChartScreenShotEpic,
} from '../Screens/VesselInformation/redux/tubeChart/tubeChartEpic';
import {
  getReportPdf1Epic, getReportPdf2Epic, getReportPdf3Epic, getReportPdf4Epic,
} from '../Screens/ScreenReportPdf/redux/reportPdfEpic';
import {
  epicGetListContentsList, epicGetWorkspaceDetailsById, epicGetWorkspaceList, sendEmailWorkspaceEpic,
  epicGetSignatureList,
  getUserDetailsWorkSpaceEpic,
  getCombineReportEpic,
} from '../Screens/taiReportingSystem/redux/epicReportWorkspace';
import { getDueListDataEpic, updateStatusEpic } from '../Screens/inspectionLetter/redux/epicInspectionLetter';
import {
  deleteTubeChartLibraryEpic,
  getTubeChartLibraryEpic, getTubeChartLibraryImageEpic,
} from '../Screens/tubeChartLibrary/redux/tubeChartLibraryEpic';

const epics = [
  epicLogin,
  epicSendOtp,
  epicOtpVerify,
  epicUnlinkSmcSite,
  epicGetSmcTabCustomerList,
  epicGetSmcTabSitesList,
  epicForgotPassword,
  epicLogout,
  epicSendOtpForgot,
  epicResetPassword,
  addCustomersEpic,
  addContactEpic,
  epicGetSmcUserList,
  epicUnlinkSmcUser,
  getContactsEpic,
  getMasterDataEpic,
  epicAddSmcDetails,
  epicGetUsersList,
  getPincodeDetailsEpic,
  getCustomersEpic,
  deleteCustomerEpic,
  epicGetUserProfile,
  epicDeleteSmc,
  epicGetSmcTabUserList,
  epicUpdateImageData,
  getCustomerByIdEpic,
  editCustomersEpic,
  deleteContactEpic,
  getContactByIdEpic,
  editContactEpic,
  epicUpdateUserProfile,
  epicUnlinkSmcCustomer,
  addCommentEpic,
  updateCommentEpic,
  epicAddSmcUserDetails,
  epicUpdateSmc,
  epicGetSmcTabUserListById,
  epicGetKitPriceList,
  epicUpdateKitPrices,
  epicGetSystemPriceList,
  epicUpdateSystemPriceLists,
  epicFreonList,
  epicDeleteFreon,
  epicFreonById,
  epicEditFreon,
  getDashboardEpic,
  getGeneralSitesEpic,
  getSiteByIdEpic,
  getAssociatedSitesEpic,
  getSiteTypesEpic,
  getJobsEpic,
  deleteJobEpic,
  addSiteEpic,
  addJobEpic,
  addJobCommentEpic,
  getJobByIdEpic,
  updateJobEpic,
  epicAddFreon,
  epicUnitPrice,
  epicGetSystemMarkup,
  getJobsEpic,
  epicUpdateMarkups,
  epicDeleteMarkups,
  epicAddMarkups,
  addOilKitEpic,
  getOilKitRangeListEpic,
  addOilRangeEpic,
  addOilRangeDeleteEpic,
  getDetailsByIdEpic,
  editOilKitEpic,
  getOilKitsSearchEpic,
  deleteOilKitsEpic,
  getOilKitsListEpic,
  epicChangePassword,
  deleteAnalystEpic,
  getCarrierDataEpic,
  getTeritoryDataEpic,
  getAnalystDataByIDEpic,
  updateAnalystDataEpic,
  getJobScheduleListDataEpic,
  getAnalystDataEpic,
  getCarrierDataEpic,
  getTeritoryDataEpic,
  addAnalystDataEpic,
  getAnalystDataByIDEpic,
  getAnalystsEpic,
  getAnalystsEpic,
  createBidEpic,
  getAnalystCityEpic,
  createUnitEpic,
  getVesselMasterEpic,
  getUnitMasterEpic,
  getUnitPriceEpic,
  getBidListingEpic,
  getBidByIdEpic,
  getUnitListingEpic,
  getUnitByIdEpic,
  getDriverExpenseEpic,
  getBidPricingEpic,
  epicCreateEquipment,
  epicEquipmentMaster,
  epicUnitMaster,
  getJobScheduleUnitDataEpic,
  getJobTravelDataEpic,
  onAddScheduleEpic,
  onUpdateScheduleEpic,
  onAddAccommodationScheduleEpic,
  onUpdateAccommodationScheduleEpic,
  onDeleteAccommodationScheduleEpic,
  getHotelListDataEpic,
  onCreateJobScheduleEpic,
  getCommentDataEpic,
  getNearestAnalystEpic,
  getCustomerAnalystEpic,
  updateBidEpic,
  associateUnitsEpic,
  updateUnitEpic,
  deleteUnitEpic,
  deleteBidEpic,
  epicGetEquipmentList,
  epicOilKitsUnitPrice,
  epicGetTestEndList,
  epicUpdateTestEndList,
  epicAddTestEndList,
  epicDeleteTestEndList,
  epicGetSupportList,
  epicUpdateSupportList,
  epicAddSupportList,
  epicDeleteSupportList,
  epicGetManufacturerList,
  epicUpdateManufacturerList,
  epicAddManufacturerList,
  epicDeleteManufacturerList,
  epicGetMaterialList,
  epicUpdateMaterialList,
  epicAddMaterialList,
  epicDeleteMaterialList,
  epicGetVesselList,
  epicUpdateVesselList,
  epicAddVesselList,
  epicDeleteVesselList,
  epicGetTubeList,
  epicUpdateTubeList,
  epicAddTubeList,
  epicDeleteTubeList,
  epicGetTypeList,
  epicUpdateTypeList,
  epicAddTypeList,
  epicDeleteTypeList,
  epicGetStyleList,
  epicUpdateStyleList,
  epicAddStyleList,
  epicDeleteStyleList,
  epicGetReportList,
  epicUpdateReportList,
  epicAddReportList,
  epicDeleteReportList,
  epicGetDefaultList,
  epicUpdateDefaultList,
  epicAddDefaultList,
  epicDeleteDefaultList,
  epicGetSitesList,
  epicDeleteSite,
  epicGetSiteTypeData,
  epicGetSiteDetailsById,
  epicUpdateUnit,
  epicGetCustomersList,
  epicGetCalibrationProcedureData,
  epicGetRecommendationCodesData,
  epicCreateRecommendationCodesData,
  epicCreateCalibrationProcedure,
  epicDeleteRecommendationCodes,
  onUpdateSiteDataEpic,
  getTravelDataByIdListEpic,
  generateInvoiceEpic,
  epicGetBidPdfDetails,
  epicGetBidsList,
  epicDeleteBid,
  getMasterEventEpic,
  createEventEpic,
  getAllEventsEpic,
  updateEventEpic,
  deleteEventEpic,
  getColorMasterDataEpic,
  getTreeDataEpic,
  epicCreateDefectCode,
  epicDeleteDefectCode,
  getTypeStyleMasterEpic,
  getAnalystLeaveDataEpic,
  deleteAnalystLeaveEpic,
  getCitySuggestionEpic,
  getInventoryDataEpic,
  deleteInventoryEpic,
  getInventoryIssueHistoryEpic,
  returnProductEpic,
  issueProductEpic,
  addEquipmentEpic,
  getInventoryMasterDataEpic,
  inventoryEquipmentRequestMaster,
  createEquipmentRequestEpic,
  equipmentRequestDataEpic,
  inventoryEquipmentRequestStatusMaster,
  inventoryEquipmentNeededMaster,
  addEquipmentByAdminEpic,
  inventoryEquipmentDeleteEpic,
  inventoryEquipmentHistoryEpic,
  inventoryEquipmentDetailsByIdEpic,
  epicGetDefectCodeList,
  epicUpdateDefectCodeList,
  epicAddDefectCodeList,
  epicDeleteDefectCodeList,
  epicGetEquipmentCategoryList,
  epicUpdateEquipmentCategoryList,
  epicAddEquipmentCategoryList,
  epicDeleteEquipmentCategoryList,
  addAnalystLeaveEpic,
  getAnalystMasterLeaveDataEpic,
  createBookMarkEpic,
  getAllBookmarksEpic,
  updateBookMarkEpic,
  getJobEventDetailsEpic,
  getPublicHolidaysEpic,
  createPublicHolidayEpic,
  getRequestDataByIdEpic,
  getProductDetailsByIdEpic,
  sendEquipmentEpic,
  getDataForPrintPdfEpic,
  getUpcomingJobsEpic,
  epicUpdateLogoData,
  addThemeEpic,
  getOilReportsEpic,
  getOilReportByIdEpic,
  updateOilReportEpic,
  getOilReportPdfEpic,
  uploadExcelEpic,
  getExcelDataEpic,
  getAllUnitsSerialNumberEpic,
  getAllComponentSerialNumberEpic,
  replaceUnitCompEpic,
  getCustomerContactListEpic,
  deleteOilReportEpic,
  getBillingPinCodeDetailsEpic,
  getRoleMasterDataEpic,
  epicOnActivateDeactivateUserManagementData,
  epicOnCreateUserManagementData,
  epicOnGetUserManagementData,
  onReadyToSendStartListEpic,
  epicGetFilteredAnalystData,
  epicOnCreateZone,
  epicOnRemoveAnalyst,
  epicGetBoardPositionData,
  epicOnRowOrderingAnalyst,
  epicGetJobBidsList,
  getJobOrderDataEpic,
  getAnalystPinCodeEpic,
  getAnalystBasePinCodeEpic,
  getAnalystSecondaryBasePinCodeEpic,
  deleteJobOrderEpic,
  getJobOrderDataByIdEpic,
  getUnitPriceJobOrderEpic,
  updateJobOrderEpic,
  getVesselInfoEpic,
  deleteVesselEpic,
  getRecommendationsEpic,
  getInspectionSummaryEpic,
  getInspectionSummaryByIdEpic,
  getReportTypeMasterDataEpic,
  getCodeMasterDataEpic,
  updateInspectionSummaryEpic,
  getStripChartEpic,
  getStripChartByIdEpic,
  UpdateStripChartEpic,
  CreateStripChartEpic,
  getPhaseChartEpic,
  getPhaseChartByIdEpic,
  deletePhaseChartEpic,
  UpdatePhaseChartEpic,
  CreatePhaseChartEpic,
  getVesselDefectInfoEpic,
  getDefectByIdEpic,
  deleteVesselDefectEpic,
  CreateVesselDefectEpic,
  UpdateVesselDefectEpic,
  AddTimeDateEpic,
  InsertDefectTimeEpic,
  SearchReplaceDefectEpic,
  getDefectDropDownDataEpic,
  getAsmeByIdEpic,
  updateAsmeEpic,
  getRecmByIdEpic,
  getStartupTextEpic,
  getFollowupTextEpic,
  updateRecomEpic,
  getExpenseReportDataEpic,
  deleteExpenseReportEpic,
  getExpenseReportByIdEpic,
  updateExpenseReportEpic,
  getScannedReceiptsEpic,
  deleteScannedReceiptsEpic,
  epicAddScannedReceiptsData,
  getDataSheetEpic,
  getDataSheetEditEpic,
  getBaylengthEpic,
  updateBayLengthEpic,
  getVesselMainByIdEpic,
  updateVesselMainEpic,
  getTubeTypeDataEpic,
  getTestEndDataEpic,
  getMaterialDataEpic,
  getSupportDataEpic,
  getVesselDataEpic,
  getAssociatedVesselsEpic,
  getGeneralVesselsEpic,
  getPhotosDataEpic,
  getPhotoByIdEpic,
  updatePhotoEpic,
  getAllVesselEpic,
  updatePhotoEditEpic,
  updateTubeChartEpic,
  getTubeChartByIdEpic,
  getTubeChartsEpic,
  AddJobReportEpic,
  getReportDataByIdEpic,
  deleteVesselReportsEpic,
  deleteStripChartEpic,
  AnalystReportsEpic,
  getHazardAnalysisDataEpic,
  getHazardAnalysisByIdEpic,
  updateHazardAnalysisEpic,
  getReportPdf1Epic,
  getReportPdf2Epic,
  getTubeChartDefectsEpic,
  getReportPdf3Epic,
  getReportPdf4Epic,
  epicGetWorkspaceList,
  epicGetWorkspaceDetailsById,
  epicGetListContentsList,
  sendEmailWorkspaceEpic,
  getDueListDataEpic,
  updateStatusEpic,
  epicGetSignatureList,
  getCustomerJobOrderEpic,
  getUserDetailsWorkSpaceEpic,
  getCombineReportEpic,
  updateTubeChartScreenShotEpic,
  getTubeChartLibraryEpic,
  deleteTubeChartLibraryEpic,
  getTubeChartLibraryImageEpic,
  getSerialNumberEpic,
  getInspectionIndicationByIdEpic,
  getJobUnitListingEpic,
  JobOrderMoveWorkspaceEpic,
];

export const rootEpic = combineEpics(...epics);
