import * as Yup from 'yup';
import { SelectedCustomerDetails } from '../../../../addCustomer/utils/types';
import { JobScheduleFields } from '../../utils/constants';
import { TravelDataByIdResult } from '../../utils/types';
import { CustomerSiteFormFields } from './constants';
import { SelectedSite, VesselsData, VesselsListingDetails } from './types';
import { formatDateString } from '../../../../../utils/CommonFunctions';

export function assignCustomerValues(setValue: SetValue, details: SelectedCustomerDetails) {
  [
    { name: CustomerSiteFormFields.CustomerName, value: details?.CompanyInformation?.CustomerName },
    // eslint-disable-next-line max-len
    { name: CustomerSiteFormFields.CustomerContact, value: `${details?.PrimaryContactInformation?.FirstName} ${details?.PrimaryContactInformation?.LastName}` },
    { name: CustomerSiteFormFields.CustomerAddress, value: details?.CompanyInformation?.Address },
    { name: CustomerSiteFormFields.CustomerOptionalAdress, value: details?.CompanyInformation?.OptionalAddress },
    { name: CustomerSiteFormFields.CustomerZip, value: details?.CompanyInformation?.ZipCode },
    { name: CustomerSiteFormFields.CustomerCountry, value: details?.CompanyInformation?.CountryCode },
    { name: CustomerSiteFormFields.CustomerState, value: details?.CompanyInformation?.StateName },
    { name: CustomerSiteFormFields.CustomerCity, value: details?.CompanyInformation?.CityName },
    { name: CustomerSiteFormFields.CustomerPhoneCode, value: details?.CompanyInformation?.PhoneCode },
    { name: CustomerSiteFormFields.CustomerPhone, value: details?.CompanyInformation?.Phone },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function assignSiteValues(setValue: SetValue, details: SelectedSite) {
  [
    { name: CustomerSiteFormFields.SiteId, value: details.SiteId },
    { name: CustomerSiteFormFields.SiteName, value: details.SiteName },
    { name: CustomerSiteFormFields.SiteContact, value: details.Contact },
    { name: CustomerSiteFormFields.SiteAddress, value: details.Address },
    { name: CustomerSiteFormFields.SiteOptionalAddress, value: details.OptionalAddress },
    { name: CustomerSiteFormFields.SiteZip, value: details.Pincode },
    { name: CustomerSiteFormFields.SiteState, value: details.State },
    { name: CustomerSiteFormFields.SiteCity, value: details.City },
    { name: CustomerSiteFormFields.SiteCountry, value: details.CountryCode },
    { name: CustomerSiteFormFields.SitePhoneCode, value: details.PhoneCode },
    { name: CustomerSiteFormFields.SitePhone, value: details.Phone },
    { name: CustomerSiteFormFields.SiteCell, value: details.Cell },
    { name: CustomerSiteFormFields.SiteType, value: details.SiteTypeId },
    { name: CustomerSiteFormFields.SiteSMC, value: details.SmcId },
    { name: CustomerSiteFormFields.SiteNote, value: details.Note },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function assignTravelValues(setValue: SetValue, travelDataById: TravelDataByIdResult) {
  setValue(JobScheduleFields.travelDirection, travelDataById?.Direction);
  setValue(JobScheduleFields.flightOutAirline, travelDataById?.FlightOutAirlines);
  setValue(JobScheduleFields.flightOutSeat, travelDataById?.FlightOutSeat);
  setValue(JobScheduleFields.flightOutConfirmation, travelDataById?.FlightOutConfirmation);
  setValue(JobScheduleFields.flightInAirline, travelDataById?.FlightInAirlines);
  setValue(JobScheduleFields.flightInSeat, travelDataById?.FlightInSeat);
  setValue(JobScheduleFields.flightInConfirmation, travelDataById?.FlightInConfirmation);
  setValue(JobScheduleFields.carRentalCompany, travelDataById?.CarRentalCompany);
  setValue(JobScheduleFields.carRentalPhone, travelDataById?.CarRentalPhone);
  setValue(JobScheduleFields.carRentalPrice, travelDataById?.CarRentalPrice);
  setValue(JobScheduleFields.carRentalConfirmation, travelDataById?.CarRentalConfirmationNumber);
  setValue(JobScheduleFields.carInstruction, travelDataById?.CarRentalInstruction);
}

export function formatVesselsTableData(data: VesselsListingDetails[]) {
  if (data.length === 0) {
    return [];
  }

  const res: VesselsData[] = [];

  data.forEach((col: VesselsListingDetails) => {
    const obj = {
      select: '',
      jobOrder: '',
      date: '',
      vessel: '',
      model: '',
      tubeCount: '',
      siteName: '',
      serial: '',
      id: '',
    };

    obj.jobOrder = col?.JobOrder;
    obj.date = formatDateString(col?.Date);
    obj.vessel = col.VesselName;
    obj.tubeCount = String(col.TubeCount);
    obj.siteName = col.SiteName;
    obj.id = String(`${col.JobOrder}(${col.VesselName})-(${col.VesselId})-(0)`);
    obj.model = col.Model;
    obj.serial = (String(col?.SerialNumber));
    res.push(obj);
  });

  return res;
}

export const siteInfoFormSchema = Yup.object({
  [JobScheduleFields.otherSiteCity]: Yup.string().test({
    name: 'required-with-siteCity',
    test(value) {
      const { siteCity } = this.parent;
      if (siteCity === 'Other') {
        return !!value || this.createError(
          {
            message: 'City is required',
          },
        );
      }
      return true;
    },
  }),
  [JobScheduleFields.siteCity]: Yup.string().test({
    name: 'required-with-siteCity1',
    test(value) {
      const { siteCity } = this.parent;
      if (siteCity === '') {
        return !!value || this.createError(
          {
            message: 'City is required',
          },
        );
      }
      return true;
    },
  }),
});

export const UnitJobDefaultValue = {
  [JobScheduleFields.siteCity]: '',
  [JobScheduleFields.siteState]: '',
};
