/* eslint-disable max-len */
import { Entries } from '../../../Common/ComponentSelectInput/types';
import EndPoints from '../../../Routes/EndPoints';
import { DocumentParts } from '../../ScreenReportPdf/constant';
import { STRIP_CHART_TAB } from '../../VesselInformation/utils/constant';
import { WorkspaceDetailsByIdResult } from './types';

export const tempTableData = [
  {
    sn: '001', vessel: 'Vessel A', dateTest: '07-01-2024', retestDate: '07-01-2025',
  },
  {
    sn: '002', vessel: 'Vessel B', dateTest: '07-02-2024', retestDate: '07-02-2025',
  },
  {
    sn: '003', vessel: 'Vessel C', dateTest: '07-03-2024', retestDate: '07-03-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
  {
    sn: '004', vessel: 'Vessel D', dateTest: '07-04-2024', retestDate: '07-04-2025',
  },
];

export const TempDataPDFArray = [
  {
    Report: '0',
    id: '2',
  },
  {
    Report: '1',
    id: '3',
  },
];

export enum ReportingFormNames {
  SerialNumber = 'SerialNumber',
  InspectedBy = 'InspectedBy',
  Site = 'Site',
  Delivery = 'Delivery',
}

export const TreeData = (apiData: WorkspaceDetailsByIdResult | null | undefined, jobOrderId: string) => {
  if (apiData) {
    const vesselsList = apiData.ReportDetails?.Vessels?.VesselsList;
    const vesselsSection = vesselsList && vesselsList.length > 0 ? [{
      Id: 158,
      Name: 'Vessels',
      TypeId: 101,
      Children: vesselsList.map((vessel, index) => ({
        Id: `Vessel-${index + 1}`,
        Name: `${vessel.vesselName}`,
        key: `Vessel-${index + 1}`,
        TypeId: 109,
        Url: `/${EndPoints.VESSEL_INFORMATION}/${jobOrderId}/${vessel.vesselId}`,
        Children: [
          {
            Id: `TubeCharts-${index + 1}`,
            Name: 'Tube Charts',
            TypeId: 102,
            key: `TubeCharts-${index + 1}`,
            Children: vessel.TubeCharts?.TubeChartIds?.map((tubeChart, tubeIndex) => ({
              Id: tubeChart.Id || `TubeChart-${index + 1}-${tubeIndex + 1}`,
              Name: vessel.TubeCharts?.TubeChartIds.length > 1 ? `TubeChart Section-${tubeIndex + 1}` : 'TubeChart',
              key: tubeChart.Id || `TubeChart-${index + 1}-${tubeIndex + 1}`,
              TypeId: 104,
              Url: `/${EndPoints.TUBE_CHART}/${tubeChart.Id}?jobOrder=${jobOrderId}&vessel=${vessel.vesselId}`,
            })) || [],
          },
          vessel.StripCharts?.StripChartIds && vessel.StripCharts.StripChartIds.length > 0 ? {
            Id: `StripCharts-${index + 1}`,
            Name: 'StripChart',
            Url: `/${EndPoints.VESSEL_INFORMATION}/${jobOrderId}/${vessel.vesselId}/${STRIP_CHART_TAB}`,
            TypeId: 104,
          } : null,
        ].filter(Boolean),
      })),
    }] : [];

    return [
      {
        Id: 4,
        Name: `Job Order # ${jobOrderId}`,
        Children: [
          {
            Id: 179,
            Name: 'Job Order',
            Children: [
              {
                Id: 189,
                Name: 'Job Order',
                Url: `/${EndPoints.JOB_ORDER}/${apiData.ReportDetails?.jobOrders?.JobOrder}`,
                TypeId: 104,
              },
              {
                Id: 180,
                Name: 'Customer',
                Url: `/${EndPoints.Customers}/${apiData.ReportDetails?.jobOrders?.Customer}`,
                TypeId: 104,
              },
            ],
          },
          ...vesselsSection,
          {
            Id: 'InspectionSummary',
            Name: 'Inspection Summary',
            Url: `/${EndPoints.Inspection_Summary}/${jobOrderId || ''}`,
            TypeId: 104,
          },
          {
            Id: 'Recommendation',
            Name: 'Recommendation',
            Url: `/${EndPoints.RECOMMENDATIONS}/${jobOrderId || ''}`,
            TypeId: 104,
          },
          {
            Id: 'DataSheet',
            Name: 'Data Sheet',
            Url: `/${EndPoints.DATASHEETS}/${jobOrderId || ''}`,
            TypeId: 104,
          },
          {
            Id: 'Photos',
            Name: 'Photos',
            Url: `/${EndPoints.PHOTOS}/${jobOrderId || ''}`,
            TypeId: 104,
          },
          {
            Id: 'ExpenseReport',
            Name: 'Expense Report',
            Url: `/${EndPoints.EXPENSE_REPORTS}/${jobOrderId || ''}`,
            TypeId: 104,
          },
          {
            Id: 'HazardAnalysis',
            Name: 'Hazard Analysis',
            Url: `/${EndPoints.HAZARD_ANALYSIS}/${jobOrderId || ''}`,
            TypeId: 104,
          },
        ],
      },
    ];
  }
  return [];
};

export const DEFAULT_DESCRIPTION_GMAIL = `2/25/2023

By Karrie Alcazar

NEW CUSTOMER-PER BECKY G. SAID DENISE 

Thank you for using TAI Services,
Inc.`;

export const reportStyleEntries:Entries[] = [
  { Text: 'PDF Report', Value: 1 },
  { Text: 'PDF Report with Photos', Value: 2 },
  { Text: 'Job Order Expense Report', Value: 3 },
];

export const reportStyleObj = {
  1: [DocumentParts.Header_With_Logo, DocumentParts.Tube_Charts,
    DocumentParts.Strip_Charts, DocumentParts.Summary_of_Inspection, DocumentParts.Recommendations, DocumentParts.Data_Sheets, DocumentParts.Table_of_Contents,
    DocumentParts.Vessel_Information, DocumentParts.Defect_Graph, DocumentParts.Calibration_Procedure, DocumentParts.Explanation_Of_Abbreviations, DocumentParts.Phase_Charts],
  2: [DocumentParts.Header_With_Logo, DocumentParts.Tube_Charts,
    DocumentParts.Strip_Charts, DocumentParts.Summary_of_Inspection, DocumentParts.Recommendations, DocumentParts.Data_Sheets, DocumentParts.Table_of_Contents,
    DocumentParts.Vessel_Information, DocumentParts.Defect_Graph, DocumentParts.Calibration_Procedure, DocumentParts.Explanation_Of_Abbreviations, DocumentParts.Phase_Charts,
    DocumentParts.Photos],
  3: [DocumentParts.Header_With_Logo, DocumentParts.Expense_Report],
};
