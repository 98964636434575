import { Button, Card, Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import { BidWorkSheetFormNames } from '../../utils/constants';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import { BidInfoFormProps, GetCustomerAnalystPayload } from '../../utils/types';
import { RootState } from '../../../../../redux/rootState';
import { getAnalystCityStart, getCustomerAnalystStart } from '../../redux/bidWorksheetSlice';
import { roundTripMilesRegex } from '../../../../../utils/regex';

export default function BidInfoForm({
  control, handleAction, watch, scrollTop,
}: BidInfoFormProps) {
  const { markups, countries } = useSelector((state:RootState) => state.masterDataReducer);
  const { siteTypes, selectedSite } = useSelector((state:RootState) => state.jobStarterReducer);
  const { analystList, analystDetails } = useSelector((state:RootState) => state.bidsReducer);
  const { otherCustomerdetails } = useSelector((state:RootState) => state.addCustomerReducer);

  const dispatch = useDispatch();

  const handleAnalystChange = (value:string) => {
    const payload = {
      analystId: value,
      siteCity: selectedSite.City,
    };
    dispatch(getAnalystCityStart(payload));
  };
  const customerSMC = watch(BidWorkSheetFormNames.customerSMC);
  const siteSMC = watch(BidWorkSheetFormNames.siteSMC);

  const handleDriveFrom = () => {
    const payload:GetCustomerAnalystPayload = {
      userCity: selectedSite?.City,
      analystCity: analystDetails?.BaseCity?.City,
    };
    dispatch(getCustomerAnalystStart(payload));
  };

  const targetDivRef5 = useRef<HTMLButtonElement | null>(null);

  const goToTop = () => {
    const targetDiv = targetDivRef5.current;
    targetDiv?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '8px' }}>
        <Button type="button" className="button_save_and_next" onClick={() => goToTop()}>Go to Save</Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Customer
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {customerSMC
              && <CustomCheckbox isDisable control={control} name={BidWorkSheetFormNames.customerSMC} label="SMC" />}
                <div className="customerID">
                  Customer Markup
                </div>
                <ComponentSelectInput
                  name={BidWorkSheetFormNames.customerMarkup}
                  control={control}
                  size="small"
                  entries={markups}
                  disabled
                  defaultValue=""
                />
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={3} sm={3} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Cust #</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.custId}
                      id={BidWorkSheetFormNames.custId}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Customer Name</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.customerName}
                      id={BidWorkSheetFormNames.customerName}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                {
                String(otherCustomerdetails?.CompanyInformation?.CustomerNote)
              && (
              <Grid item md={3} sm={3} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">&nbsp;</label>
                  <Button
                    className="button_save_and_next"
                    size="medium"
                    onClick={handleAction}
                  >
                    Customer Alert

                  </Button>
                </div>
              </Grid>
              )
}
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Site Information
              </div>
              <div className="double_checkbox">
                <CustomCheckbox control={control} name={BidWorkSheetFormNames.gulfCoastFL} label="Gulf Coast FL" />
                { siteSMC && <CustomCheckbox isDisable control={control} name={BidWorkSheetFormNames.siteSMC} label="SMC" />}
                <CustomCheckbox isDisable control={control} name={BidWorkSheetFormNames.outOfCountry} label="Out of Country" />
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Site</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.site}
                      id={BidWorkSheetFormNames.site}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Address</label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      name={BidWorkSheetFormNames.address}
                      id={BidWorkSheetFormNames.address}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Zip</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.zip}
                      id={BidWorkSheetFormNames.zip}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">State</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.state}
                      id={BidWorkSheetFormNames.state}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">City</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.city}
                      id={BidWorkSheetFormNames.city}
                      isDisable
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Country</label>
                    <ComponentSelectInput
                      name={BidWorkSheetFormNames.country}
                      control={control}
                      defaultValue=""
                      disabled
                      size="small"
                      entries={countries}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Site Type</label>
                    <ComponentSelectInput
                      name={BidWorkSheetFormNames.siteType}
                      control={control}
                      defaultValue=""
                      disabled
                      size="small"
                      entries={siteTypes}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Nearest Analyst
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Analyst Name</label>
                    <ComponentSelectInput
                      name={BidWorkSheetFormNames.analystName}
                      control={control}
                      handleChange={handleAnalystChange}
                      size="small"
                      defaultValue=""
                      entries={analystList}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Drive From</label>
                    <ComponentSelectInput
                      name={BidWorkSheetFormNames.drivenFrom}
                      control={control}
                      handleChange={handleDriveFrom}
                      defaultValue=""
                      size="small"
                      entries={analystDetails?.AnalystCities || []}
                    />
                  </div>
                </Grid>
                <div style={{ display: 'none' }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Address</label>
                      <ComponentTextInput
                        control={control}
                        className="nonCapitalizeField"
                        name={BidWorkSheetFormNames.analystAddress}
                        id={BidWorkSheetFormNames.analystAddress}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Zip</label>
                      <ComponentTextInput
                        control={control}
                        name={BidWorkSheetFormNames.analystZip}
                        id={BidWorkSheetFormNames.analystZip}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">State</label>
                      <ComponentTextInput
                        control={control}
                        name={BidWorkSheetFormNames.analystState}
                        id={BidWorkSheetFormNames.analystState}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">City</label>
                      <ComponentTextInput
                        control={control}
                        name={BidWorkSheetFormNames.analystCity}
                        id={BidWorkSheetFormNames.analystCity}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Country</label>
                      <ComponentTextInput
                        control={control}
                        name={BidWorkSheetFormNames.analystCountry}
                        id={BidWorkSheetFormNames.analystCountry}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                </div>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Round Trip Miles</label>
                    <ComponentTextInput
                      control={control}
                      name={BidWorkSheetFormNames.roundTripMiles}
                      id={BidWorkSheetFormNames.analystCountry}
                      regEx={roundTripMilesRegex}
                      defaultValue=""
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="save_and_next_div">
            <Button ref={targetDivRef5} type="submit" className="button_save_and_next" onClick={scrollTop}>Save</Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
